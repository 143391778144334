import { SET_GRUPOS, SET_HIJOS, LOGOUT } from '../constants'

export const grupos = (state = { lista: [], hijos: {} }, a) => {
	switch (a.type) {
		case SET_GRUPOS:
			return { ...state, lista: a.payload }
		case SET_HIJOS:
			return { ...state, hijos: { ...state.hijos, [a.payload.id]: a.payload.data } }
		case LOGOUT:
			return {}
		default:
			return state
	}
}

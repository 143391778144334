import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	List,
	ListItem,
	ListSubheader,
	ListItemText,
	Button,
	ListItemIcon,
	Checkbox,
	Slide,
	Collapse,
	Divider,
	Card,
	CardActions,
	CardHeader,
	makeStyles
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { setCursos } from '../../../actions/curso/setCursos'
import { setGrupos } from '../../../actions/grupos/setGrupos'
import { setHijos } from '../../../actions/grupos/setHijos'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { apiGetWithParams, apiGet, apiPost } from '../../../api'
import { urlUsuarios, urlGrupos, urlCurso, urlDivisiones } from '../../../api/urls'
import { green, red } from '@material-ui/core/colors'
import { AddBox, CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		height: '100%'
	},
	leftWrapper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		flex: 1,
		marginRight: 5
	},
	cardList: {
		flex: 1,
		backgroundColor: theme.palette.background.paper,
		borderRadius: 4,
		height: '100%',
		overflowY: 'auto',
		overflowX: 'hidden',
		paddingTop: 0
	},
	listaUsuarios: {
		display: 'flex',
		flex: 2,
		flexDirection: 'column',
		height: '100%',
		marginLeft: 5,
		marginBottom: 'auto'
	},
	listaGrupos: {
		overflow: 'auto'
	},
	cardGruposContent: {
		flex: 1
	},
	lista: {
		margin: 'auto',
		height: '100%'
	},
	paper: {
		width: '100%',
		height: '100%',
		overflow: 'auto'
	},
	buttons: {
		alignSelf: 'flex-end'
	},
	button: {
		margin: theme.spacing(0.5, 0)
	}
}))

export default function Asignar() {
	const [ state, setState ] = useState({ cursoSeleccionado: -1, left: [], right: [], checked: [], open: {}, old: [] })
	const cursos = useSelector(state => state.curso.cursos)
	const grupos = useSelector(state => state.grupos.lista)
    const hijos = useSelector(state => state.grupos.hijos)
    const dispatch = useDispatch()
    const classes = useStyles()

	const getCursos = () => {
		dispatch(startLoading())
		apiGetWithParams(urlCurso, { img: 'falso' }).then(r => {
			if (r.status === 'OK') dispatch(setCursos(r.data))
			else dispatch(openSnack({ texto: 'Error al obtener el listado de cursos', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const getTodosGrupos = () => {
		dispatch(startLoading())
		apiGet(urlGrupos)
			.then(r => {
				if (r.status === 'OK') dispatch(setGrupos(r.data))
				else dispatch(openSnack({ texto: 'Error al obtener el listado de grupos', tipo: 'error' }))
				dispatch(endLoading())
			})
			.catch(_ => dispatch(endLoading()))
	}

	useEffect(() => {
		getTodosGrupos()
        getCursos()
        // eslint-disable-next-line
	}, [])

	const getUsuariosCurso = curso => {
		dispatch(startLoading())
		apiGetWithParams(urlUsuarios, { cursoId: curso.id }).then(r => {
			if (r.status === 'OK') {
				const newData = r.data.map(e => e.id)
				setState(ps => ({...ps, checked: newData, old: newData }))
			} else dispatch(openSnack({ texto: 'Error al obtener el listado de usuarios', tipo: 'error' }))
			dispatch(endLoading())
		})
		dispatch(startLoading())
		apiGetWithParams(urlGrupos, { cursoId: curso.id })
			.then(r => {
				if (r.status === 'OK') {
					dispatch(setGrupos(r.data))
				} else dispatch(openSnack({ texto: 'Error al obtener el detalle de los grupos', tipo: 'error' }))
				dispatch(endLoading())
			})
			.catch(_ => dispatch(endLoading()))
	}

	const getUsuariosGrupo = grupo => {
        dispatch(startLoading())
        console.log(grupo)
		apiGetWithParams(urlUsuarios, { grupoId: grupo.id }).then(r => {
			if (r.status === 'OK') dispatch(setHijos({ id: grupo.id, data: r.data }))
			else dispatch(openSnack({ texto: 'Error al obtener el listado de usuarios', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const renderSubLista = cursos => {
		const { cursoSeleccionado } = state
		return cursos.map((c, i) => (
			<ListItem
				button
				key={`item-${i}-${c.id}`}
				selected={cursoSeleccionado === c.id}
				onClick={() => {
					setState(ps => ({...ps, cursoSeleccionado: c.id }))
					getUsuariosCurso(c)
				}}
			>
				<ListItemText primary={c.nombre} />
			</ListItem>
		))
	}

	const handleToggle = value => {
		var newChecked = [ ...state.checked ]
		const idx = newChecked.indexOf(value.id)
		if (idx === -1) {
			newChecked.push(value.id)
		} else {
			newChecked.splice(idx, 1)
		}
		setState(ps=> ({...ps, checked: newChecked }))
	}

	const isChecked = grupo => {
		var res = true
		if (hijos[grupo.id])
			hijos[grupo.id].forEach(e => {
				if (state.checked.indexOf(e.id) === -1) res = false
			})
		else res = false
		return res
	}

	const onSelectAllClick = (grupo, allChecked) => {
		var newChecked = [ ...state.checked ]
		if (hijos[grupo.id])
			hijos[grupo.id].forEach(e => {
				if (allChecked) {
					const idx = newChecked.indexOf(e.id)
					if (idx !== -1) newChecked.splice(idx, 1)
				} else if (state.checked.indexOf(e.id) === -1) newChecked.push(e.id)
			})
		setState(ps => ({...ps, checked: newChecked }))
    }
    
    console.log(state)

	const lista = () => {
		const { checked, open, old } = state
		return (
			<List className={classes.cardList} dense component='div' role='list'>
				<ListSubheader color='primary'>Grupos</ListSubheader>
				{[ ...grupos, { nombre: 'Sin grupo', id: -1 } ].map((g, i) => {
					const allChecked =
						hijos[g.id] || g.id === -1 ? isChecked(g) : g.cantUsuarios === g.cantAsignados
					return (
						<React.Fragment key={'qweqwww-' + i}>
							<ListItem key={'asddww-' + i} color={'primary'}>
								<ListItemIcon key={'liri-' + i}>
									<Checkbox
										key={'gfgdfg-' + i}
										color='primary'
										disabled={!hijos[g.id]}
										checked={g.cantUsuarios === 0 ? false : allChecked}
										onChange={() => onSelectAllClick(g, allChecked)}
										inputProps={{ 'aria-label': 'select all' }}
									/>
								</ListItemIcon>
								<ListItem
									button
									key={'asddww-' + i}
									color={'primary'}
									onClick={() => {
										if (!hijos[g.id]) getUsuariosGrupo(g)
										setState(ps => ({...ps,  open: { ...open, [g.id]: !open[g.id] } }))
									}}
								>
									<ListItemText
										key={'litdds-' + i}
										id={g.id}
										primary={`${g.nombre} ${g.id === -1
											? ''
											: g.cantUsuarios > 0
												? `(${g.cantAsignados}/${g.cantUsuarios})`
												: '(Vacío)'}`}
									/>
									{open[g.id] ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
							</ListItem>
							<Collapse in={hijos[g.id] && open[g.id]} timeout='auto' unmountOnExit>
								{hijos[g.id] ? (
									<List
										style={{ marginBottom: 10, marginLeft: 10 }}
										component='div'
										disablePadding
										role='list'
									>
										{hijos[g.id].length ? (
											hijos[g.id].map(value => {
												const oldValue = old.indexOf(value.id)
												const check = checked.indexOf(value.id) !== -1
												return (
													<ListItem
														key={value.id}
														role='listitem'
														style={{
															paddingLeft: 0,
															marginLeft: 32,
															borderLeft: '1px solid lightgrey'
														}}
													>
														<ListItemIcon key={'liri-' + value.id}>
															<Checkbox
																color='primary'
																icon={
																	oldValue !== -1 ? (
																		<IndeterminateCheckBox />
																	) : (
																		<CheckBoxOutlineBlank />
																	)
																}
																checkedIcon={
																	oldValue === -1 ? (
																		<AddBox />
																	) : (
																		<CheckBox color='primary' />
																	)
																}
																style={{
																	color:
																		oldValue !== -1
																			? check ? 'inherit' : red[600]
																			: check ? green[600] : 'inherit',
																	'&$checked': {
																		color: oldValue === -1 ? green[600] : 'inherit'
																	}
																}}
																key={'cksds-' + value.id}
																checked={check}
																tabIndex={-1}
																disableRipple
																onClick={() => handleToggle(value)}
															/>
														</ListItemIcon>
														<ListItemText
															key={'lutsdad-' + value.id}
															primary={`${value.nombreCompleto}`}
														/>
													</ListItem>
												)
											})
										) : (
											<ListItem style={{ paddingLeft: 32 }} dense>
												<ListItemText primary='Grupo vacío' />
											</ListItem>
										)}
									</List>
								) : null}
							</Collapse>
						</React.Fragment>
					)
				})}
			</List>
		)
	}

	const renderCursos = cursos => {
		let ultCurso = {}
		if (cursos.length < 1) return <ListSubheader color='default'>Aún no hay cursos cargados</ListSubheader>
		return cursos.map((c, i) => {
			if (ultCurso.tematica !== c.tematica) {
				ultCurso = c
				return (
					<li key={`section-${i}`} style={{ backgroundColor: 'inherit' }}>
						<ul style={{ backgroundColor: 'inherit', padding: 0 }}>
							<ListSubheader color={'primary'} key={'lsh-' + i}>
								{c.tematica}
							</ListSubheader>
							{renderSubLista(cursos.filter(c => c.tematica === ultCurso.tematica))}
						</ul>
					</li>
				)
			}
			return null
		})
	}

	const deshacerCambios = () => {
		setState(ps => ({...ps, checked: state.old }))
	}

	const guardarCambios = () => {
		dispatch(startLoading())
		var nuevos = []
		var eliminados = []
		state.checked.forEach(e => {
			if (state.old.indexOf(e) === -1) nuevos.push(e)
		})
		state.old.forEach(e => {
			if (state.checked.indexOf(e) === -1) eliminados.push(e)
		})
		apiPost(urlDivisiones, { cursoId: state.cursoSeleccionado, bulk: true, nuevos, eliminados }).then(r => {
			if (r.status === 'OK') {
			    getUsuariosCurso({ id: state.cursoSeleccionado })
				dispatch(openSnack({ texto: 'Cambios guardados con exito', tipo: 'success' }))
			} else dispatch(openSnack({ texto: 'Error al guardar cambios', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const { cursoSeleccionado } = state
	return (
		<div className={classes.root}>
			<Card className={classes.leftWrapper}>
				<CardHeader titleTypographyProps={{ color: 'primary', variant: 'h5' }} title='Asignar a cursos' />
				<Divider />
				<List className={classes.cardList}>{renderCursos(cursos)}</List>
			</Card>
			<Slide direction='left' in={cursoSeleccionado !== -1} mountOnEnter unmountOnExit>
				<Card className={classes.listaUsuarios}>
					<CardHeader title='Seleccionar' titleTypographyProps={{ variant: 'h5', color: 'primary' }} />
					<Divider />
					{lista()}
					<Divider />
					<CardActions className={classes.buttons}>
						<Button color='secondary' variant='text' onClick={deshacerCambios}>
							Deshacer cambios
						</Button>
						<Button color='primary' variant='contained' onClick={guardarCambios}>
							Guardar cambios
						</Button>
					</CardActions>
				</Card>
			</Slide>
		</div>
	)
}

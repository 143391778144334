import { SET_CURSO, SET_CURSOS, LOGOUT } from '../constants'

export const curso = (
	state = {
		curso: {
			id: window.location.pathname
				.substring(window.location.pathname.indexOf('/dashboard'), window.location.pathname.length)
				.split('/')[4]
		},
		cursos: []
	},
	a
) => {
	switch (a.type) {
		case SET_CURSO:
			return { ...state, curso: a.payload }
		case SET_CURSOS:
			return { ...state, cursos: a.payload }
		case LOGOUT:
			return {}
		default:
			return state
	}
}

import React, { useState } from 'react'
import { Box, Button, Card, IconButton, ListItemIcon, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import { apiPost } from '../../../api'
import { urlDivisiones } from '../../../api/urls'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { openSnack } from '../../../actions/feedback/openSnack'
import { useDispatch, useSelector } from 'react-redux'
import { grey } from '@material-ui/core/colors'
import { hasPermission, permisos } from '../../../constants/permisos'
import { Delete, Edit, MoreVert } from '@material-ui/icons'
import VerCursoDialog from './VerCursoDialog'

const useStyles = makeStyles(theme => ({
	root: {
		margin: 15,
		padding: '10px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: 290,
		//height: 400,
		//boxShadow: theme.shadows[1],
		'&:hover': {
			boxShadow: theme.shadows[5],
			//border: 'none'
			//cursor: 'pointer'
		}
	},
	desc: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	tematica: {
		backgroundColor: theme.palette.success.main,
		padding: 2,
		color: theme.palette.getContrastText(theme.palette.success.main),
		borderRadius: 4
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	imagen: {
		width: 150,
		height: 150,
		/* los siguientes valores son independientes del tamaño del círculo */
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50%',
		borderRadius: '50%',
		backgroundSize: '100% auto',
		border: '1px solid ' + grey['300']
	},
	button: {
		margin: '0px 5px'
	},
	buttons: {
		marginTop: 'auto'
	},
	menu: {
		boxShadow:
			'0px 6px 3px 1px rgba(0,0,0,0.02), 0px 6px 7px 0px rgba(0,0,0,0.02), 0px 6px 7px 4px rgba(0,0,0,0.02)'
	},
	menuButton: {
		alignSelf: 'flex-end'
	},
	descripcion: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	}
}))

export default function CursoItem(props) {
	const classes = useStyles()
	const defaultImage = useSelector(state => state.metadata.imagePlaceholder)
	const dispatch = useDispatch()
	const [ menu, setMenu ] = useState(null)
	const rol = useSelector(state => state.login.rol)
	const infoPermisos = useSelector(state => state.permisos)
	const editarPermiso = hasPermission(rol, infoPermisos, permisos.EDITAR_CURSO)
	const eliminarPermiso = hasPermission(rol, infoPermisos, permisos.ELIMINAR_CURSO)

	const { curso, alumnoId, reload, editar, eliminar, verCurso } = props

	const obtenerCurso = () => {
		dispatch(startLoading())
		apiPost(urlDivisiones, { cursoId: curso.id, alumnoId }).then(r => {
			if (r.status === 'OK') {
				dispatch(openSnack({ texto: 'Agregado a Mis Cursos', tipo: 'success' }))
				reload()
			} else dispatch(openSnack({ texto: 'Error al obtener curso', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	return (
		<Card className={classes.root}>
			{editarPermiso || eliminarPermiso ? (
				<React.Fragment>
					<IconButton className={classes.menuButton} onClick={e => setMenu(e.currentTarget)}>
						<MoreVert />
					</IconButton>
					<Menu
						classes={{ paper: classes.menu }}
						anchorEl={menu}
						open={Boolean(menu)}
						onClose={() => setMenu(null)}
					>
						{editarPermiso ? (
							<MenuItem
								onClick={() => {
									editar(curso)
									setMenu(null)
								}}
							>
								<ListItemIcon>
									<Edit color='primary' fontSize='small' />
								</ListItemIcon>
								<Typography variant='inherit'>Editar</Typography>
							</MenuItem>
						) : null}
						{eliminarPermiso ? (
							<MenuItem
								onClick={() => {
									eliminar(curso)
									setMenu(null)
								}}
							>
								<ListItemIcon>
									<Delete color='secondary' fontSize='small' />
								</ListItemIcon>
								<Typography variant='inherit'>Eliminar</Typography>
							</MenuItem>
						) : null}
					</Menu>
				</React.Fragment>
			) : null}
			<Box className={classes.img}>
				<img
					className={classes.imagen}
					src={curso.icono || defaultImage}
					alt={'imagen del curso ' + curso.nombre}
					height='100px'
					width='100px'
				/>
			</Box>
			<Box className={classes.desc}>
				<Typography align='center' paragraph variant='caption' className={classes.tematica}>
					{curso.tematicaNombre}
				</Typography>
				<Typography align='center' paragraph>
					{curso.nombre}
				</Typography>
				<Typography paragraph variant='body2' align='center'>
					{curso.breveDescripcion}
				</Typography>
			</Box>
			<Box className={classes.buttons}>
				<Button className={classes.button} variant='outlined' color='secondary' onClick={verCurso}>
					Ver mas
				</Button>
				<Button
					className={classes.button}
					variant={curso.adquirido ? 'text' : 'contained'}
					color='primary'
					disabled={curso.adquirido}
					onClick={obtenerCurso}
				>
					{curso.adquirido ? 'Ya adquirido' : 'Obtener'}
				</Button>
			</Box>
		</Card>
	)
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.css'
import Dashboard from './Components/Dashboard'
import Login from './Components/Login'
import { ThemeProvider, createMuiTheme, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { Route, Switch, useHistory } from 'react-router-dom'
import { login } from './actions/login/login'
import { urlLogin, urlMetadata } from './api/urls'
import { openSnack as showSnack } from './actions/feedback/openSnack'
import { closeSnack } from './actions/feedback/closeSnack'
import { setMetadata } from './actions/metadata/setMetadata'
import { blue, green, grey, red } from '@material-ui/core/colors'
import { apiGet, apiPost } from './api'
import { light } from '@material-ui/core/styles/createPalette'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

export default function App() {
	const dispatch = useDispatch()
	const userData = useSelector(state => state.login)
	const { openSnack, texto, tipo } = useSelector(state => state.feedback)
	const prefersDarkMode = useSelector(state => state.theme.prefersDarkMode)
	const history = useHistory()

	//const lightPrimary = '#1b8bcd'
	const lightPrimary = '#1b346b'
	const darkPrimary = blue[300]

	const lightSecondary = red[500]
	const darkSecondary = red[300]

	const theme = createMuiTheme({
		palette: {
			type: prefersDarkMode ? 'dark' : 'light',
			primary: { main: !prefersDarkMode ? lightPrimary : darkPrimary },
			secondary: { main: !prefersDarkMode ? lightSecondary : darkSecondary },
			background: {
				default: prefersDarkMode ? '#121212' : '#fafafa',
				level1: prefersDarkMode ? '#212121' : '#fff',
				level2: prefersDarkMode ? '#313131' : '#f5f5f5',
				paper: prefersDarkMode ? '#424242' : '#fff'
			},
			success: { main: prefersDarkMode ? green[300] : green[600] },
			error: { main: prefersDarkMode ? red[300] : red[600] },
			title: { main: prefersDarkMode ? grey[100] : grey[700] },
			colorTitle: { main: prefersDarkMode ? grey[100] : lightPrimary }
		},
		props: {
			MuiButton: {
				disableElevation: true,
				style: { textTransform: 'none' }
			},
			MuiPaper: {
				variant: 'outlined'
			}
		}
	})

	useEffect(
		() => {
			if (
				localStorage.getItem('Authorization') &&
				localStorage.getItem('Authorization').indexOf('Token') !== -1
			) {
				apiPost(urlLogin, { token: true })
					.then(r => {
						dispatch(login(r))
						if (r.status !== 'OK') history.push('/login')
						else if (window.location.pathname === '/login') history.push('/dashboard')
					})
					.catch(e => console.log(e))
			} else history.push('/login')
			apiGet(urlMetadata).then(r => {
				if (r.status === 'OK') {
					dispatch(setMetadata(r.data))
				} else dispatch(showSnack({ texto: 'Error al obtener datos de la aplicacion', tipo: 'error' }))
			})
		},
		[ dispatch, history ]
	)

	return (
		<ThemeProvider theme={theme}>
			<Snackbar open={openSnack} onClose={() => dispatch(closeSnack())} autoHideDuration={5000}>
				<Alert onClose={() => dispatch(closeSnack())} severity={tipo}>
					{texto}
				</Alert>
			</Snackbar>
			<Switch>
				{userData && userData.token ? (
					<Route path='/dashboard'>
						<Dashboard />
					</Route>
				) : null}
				{userData && userData.token ? (
					<Route exact path='/'>
						<Dashboard />
					</Route>
				) : null}
				<Route path='/login'>
					<Login />
				</Route>
			</Switch>
		</ThemeProvider>
	)
}

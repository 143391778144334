import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import { green, grey } from '@material-ui/core/colors'
import { Menu, MenuItem } from '@material-ui/core'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [ el, index ])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, dataCount, onRequestSort, cols, rowOptions, menuOptions } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{menuOptions? <TableCell padding='checkbox'>
					<Checkbox
						color={'primary'}
						indeterminate={numSelected > 0 && numSelected < dataCount}
						checked={dataCount > 0 && numSelected === dataCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
						disabled={rowCount !== dataCount}
					/>
				</TableCell>:  null}
				{cols.map(headCell => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
						</TableSortLabel>
					</TableCell>
				))}
				{rowOptions ? <TableCell>{''}</TableCell> : null}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1)
	},
	highlight: {
		color: theme.palette.getContrastText(theme.palette.background.paper),
		backgroundColor: 'transparent'
	},
	title: {
		flex: '1 1 100%'
	}
}))

const EnhancedTableToolbar = props => {
	const classes = useToolbarStyles()
	const { numSelected, openMenu } = props

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0
			})}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color='inherit' variant='subtitle1' component='div'>
					{numSelected} {numSelected === 1 ? 'seleccionado' : 'seleccionados'}
				</Typography>
			) : (
				<Typography className={classes.title} variant='h6' id='tableTitle' component='div' />
			)}

			{numSelected > 0 ? (
				<Tooltip title='Opciones'>
					<IconButton color='inherit' aria-label='options' onClick={e => openMenu(e.currentTarget)}>
						<MoreVertRoundedIcon />
					</IconButton>
				</Tooltip>
			) : null}
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
}

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		boxShadow: 'none'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		boxShadow: 'none',
		borderRadius: 4
	},
	table: {
		//minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	tableRow: {
		cursor: 'pointer',
		'&$selected, &$selected:hover': {
			backgroundColor: theme.palette.type === 'dark' ? 'rgb(165 214 167 / 0.27)' : green[200]
		}
	},
	tableCell: {
		'$selected &': {
			color: theme.palette.type === 'dark' ? 'rgb(165 214 167 / 0.27)' : green[200]
		}
	},
	selected: {},
	menuItem: {
		color: theme.palette.type === 'dark'? 'inherit' : grey[800]
	}
}))

export default function GenericTable(props) {
	const classes = useStyles()
	const [ order, setOrder ] = React.useState('asc')
	const [ orderBy, setOrderBy ] = React.useState('calories')
	const [ selected, setSelected ] = React.useState([])
	const [ page, setPage ] = React.useState(0)
	const [ menu, setMenu ] = React.useState(null)
	const [ openRowOptions, setOpenRowOptions ] = React.useState({ anchorEl: null, selected: {} })
	const [ rowsPerPage, setRowsPerPage ] = React.useState(10)

	const { cols, data, search, menuOptions, rowOptions } = props

	useEffect(()=>{
		setSelected([])
	}, [data])

	const rows = data.filter(d => {
		let b = false
		cols.forEach(e => {
			if (e.search) b = d[e.id].toLowerCase().indexOf(search.toLowerCase()) !== -1 || b
		})
		return b
	})

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.id)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}

		setSelected(newSelected)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const isSelected = name => selected.indexOf(name) !== -1

	return (
		<div className={classes.root}>
			<Menu open={Boolean(menu)} anchorEl={menu} onClose={() => setMenu(null)}>
				{menuOptions &&
					menuOptions.map((o, i) => (
						<MenuItem key={'mi-' + i} onClick={() => o.action(selected)}>
							{o.label}
						</MenuItem>
					))}
			</Menu>
			<Menu open={Boolean(openRowOptions.anchorEl)} anchorEl={openRowOptions.anchorEl} onClose={() => setOpenRowOptions({ anchorEl: null })}>
				{rowOptions &&
					rowOptions.map((o, i) => (
						<MenuItem 
							className={classes.menuItem}
							key={'mi2-' + i} onClick={() => {
								o.action(openRowOptions.selected, selected)
								setOpenRowOptions({...openRowOptions, anchorEl: null})
							}}
						>
							{o.icon? <o.icon style={{marginRight: 10}}/> : null}
							{o.label}
						</MenuItem>
					))}
			</Menu>
			<Paper className={classes.paper}>
				{selected.length > 0 ? <EnhancedTableToolbar openMenu={setMenu} numSelected={selected.length} /> : null}
				<TableContainer>
					<Table className={classes.table} aria-labelledby='tableTitle' size={'medium'} aria-label='enhanced table'>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							dataCount={data.length}
							cols={cols}
							rowOptions={rowOptions}
							menuOptions={menuOptions}
						/>
						<TableBody>
							{stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
								const isItemSelected = isSelected(row.id)
								const labelId = `enhanced-table-checkbox-${i}`

								return (
									<TableRow
										className={classes.tableRow}
										classes={{
											selected: classes.selected
										}}
										hover
										// onClick={event => handleClick(event, row.id)}
										role='checkbox'
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.id}
										selected={isItemSelected}
									>
										{menuOptions? <TableCell onClick={event => handleClick(event, row.id)} className={classes.tableCell} padding='checkbox'>
											<Checkbox color='primary' checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
										</TableCell>:null}
										{cols.map((c, j) => (
											<TableCell onClick={event => menuOptions? handleClick(event, row.id) : {}} key={'tc-' + i + j}>
												{c.boolean ? row[c.id] ? 'Si' : 'No' : row[c.id]}
											</TableCell>
										))}
										{rowOptions ? (
											<TableCell className={classes.tableCell} padding='checkbox'>
												<IconButton onClick={e => setOpenRowOptions({ anchorEl: e.currentTarget, selected: row })}>
													<MoreVertRoundedIcon fontSize='small' />
												</IconButton>
											</TableCell>
										) : null}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
				{rows.length < 1? <Typography align='center'>No hay datos para mostrar</Typography>:null}
				<TablePagination
					rowsPerPageOptions={[ 5, 10, 25 ]}
					component='div'
					count={rows.length}
					labelRowsPerPage='Filas por página: '
					labelDisplayedRows={(pagInfo, label) => {
						const { from, to, count } = pagInfo
						return `${from} - ${to} de ${count}`
					}}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	)
}

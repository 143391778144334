import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Typography, Fab, CircularProgress, Avatar, Tooltip, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import colorInterpolate from 'color-interpolate'

import { setCurso } from '../../../actions/curso/setCurso'
import { setCursos } from '../../../actions/curso/setCursos'
import { apiGet } from '../../../api'
import { urlCurso } from '../../../api/urls'
import { permisos, hasPermission } from '../../../constants/permisos'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row'
	},
	wrapper: {
		margin: theme.spacing(1),
		display: 'flex',
		alignItems: 'flex-start'
		// position: 'relative'
	},
	buttonSuccess: {
		width: 100,
		height: 100
	},
	fabProgress: {
		position: 'absolute',
		// top: -6,
		// left: -6,
		zIndex: -1
	},
	addButton: {
		position: 'fixed',
		bottom: theme.spacing(3),
		right: theme.spacing(3),
		zIndex: 1000
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	dialogContent: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	input: {
		marginBottom: 10
	},
	menu: {
		boxShadow:
			'0px 6px 3px 1px rgba(0,0,0,0.02), 0px 6px 7px 0px rgba(0,0,0,0.02), 0px 6px 7px 4px rgba(0,0,0,0.02)'
	},
	editTooltip: {
		backgroundColor: 'white',
		color: 'black',
		maxWidth: 200,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid ' + theme.palette.primary.main
	},
	editTooltipArrow: {
		color: theme.palette.primary.main
	}
}))

const inter = colorInterpolate([ '#f44336', '#ff9800', '#ffeb3b', '#cddc39', '#8bc34a', '#4caf50' ])

const getProgressColor = value => {
	return inter(value / 100)
}

const FilaDeCursos = props => {
	const { classes, setRedirect, cursos, cursoActivo, crearUnidadPermiso } = props
	return (
		<Grid container xs={12} item spacing={2} wrap='wrap'>
			{cursos.map((c, i) => (
				<Tooltip
					key={'tt-' + i}
					open={c.correlativo_padre ? undefined : false}
					classes={{ tooltip: classes.editTooltip, arrow: classes.editTooltipArrow }}
					placement='top'
					title={'Correlativa: ' + c.correlativa_nombre}
					arrow
				>
					<Grid key={'gr-' + i} item style={{ marginBottom: 20, maxWidth: 155 }}>
						<div key={'dvwq-' + i} className={classes.wrapper}>
							<div>
								<Fab
									key={'fab352-' + i}
									disabled={!cursoActivo(c.correlativo_padre) && !crearUnidadPermiso}
									aria-label='curso'
									size='large'
									color='inherit'
									className={classes.buttonSuccess}
									onClick={() => setRedirect(c, '/dashboard/miscursos/curso/' + c.id)}
								>
									<Avatar
										key={'a567-' + i}
										className={classes.buttonSuccess}
										src={c.icono ? c.icono : null}
									/>
									<CircularProgress
										key={'cp75676-' + i}
										size={112}
										variant='static'
										value={c.progreso}
										className={classes.fabProgress}
										style={{ color: getProgressColor(c.progreso) }}
									/>
								</Fab>
							</div>
						</div>
						<Typography
							key={'tg78676-' + i}
							style={{
								whiteSpace: 'normal',
								color: !cursoActivo(c.correlativo_padre) ? 'grey' : 'inherit'
							}}
							align='left'
						>
							{c.nombre}
						</Typography>
					</Grid>
				</Tooltip>
			))}
		</Grid>
	)
}

export default function Cursos() {
	const dispatch = useDispatch()
	const [ intentos, setIntentos ] = useState(0)
	const classes = useStyles()
	const history = useHistory()
	const cursos = useSelector(state => state.curso.cursos)
	const rol = useSelector(state => state.login.rol)
	const infoPermisos = useSelector(state => state.permisos)

	const getCursos = () => {
		dispatch(startLoading())
		apiGet(urlCurso).then(r => {
			if (r.status === 'OK') {
				const ordenado = r.data.sort((a, b) => {
					if (a.tematicaId !== b.tematicaId) return 1
					if (a.correlativo_padre === b.id) return 1
					if (a.id === b.correlativo_padre) return -1
					return 1
				})
				dispatch(setCursos(ordenado))
				dispatch(endLoading())
			} else if (intentos < 10)
				setTimeout(() => {
					setIntentos(intentos + 1)
					getCursos()
				}, 1000)
			else {
				dispatch(endLoading())
				dispatch(openSnack({ texto: 'Error al obtener lista de cursos', tipo: 'error' }))
			}
		})
	}

	useEffect(() => {
		getCursos()
		// eslint-disable-next-line
	}, [])

	const setRedirect = (curso, redirect) => {
		dispatch(setCurso(curso, history.push(redirect)))
	}

	const cursoActivo = idPadre => {
		const curso = cursos.filter(c => c.id === idPadre)
		return curso.length ? curso[0].completado : true
	}

	const renderCursos = cursos => {
		let ultCurso = {}
		return cursos.map((c, i) => {
			if (ultCurso.tematica !== c.tematica) {
				ultCurso = c
				return (
					<React.Fragment key={'rf325-' + i}>
						<Typography key={'te3455-' + i} variant='h6'>
							{c.tematicaNombre}
						</Typography>
						<Grid key={'gr3242-' + i} container spacing={2}>
							<FilaDeCursos
								key={'fc5534-' + i}
								classes={classes}
								setRedirect={setRedirect}
								cursos={cursos.filter(c => c.tematica === ultCurso.tematica)}
								cursoActivo={cursoActivo}
								crearUnidadPermiso={hasPermission(rol, infoPermisos, permisos.CREAR_UNIDAD)}
							/>
						</Grid>
					</React.Fragment>
				)
			}
			return null
		})
	}

	return (
		<React.Fragment>
			{cursos && cursos.length ? (
				renderCursos(cursos)
			) : (
				<Typography variant='h5'>Todavia no tenes cursos disponibles</Typography>
			)}
		</React.Fragment>
	)
}

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Entrada from './Entrada'
import MiCurso from './MiCurso'
import Unidad from './Unidad'

export default function MisCursos() {
	return (
		<Switch>
			<Route path='/dashboard/miscursos/curso/:idcurso/unidad/:idunidad'>
				<Unidad />
			</Route>
			<Route path='/dashboard/miscursos/curso/:idcurso'>
				<MiCurso />
			</Route>
			<Route path='/dashboard/miscursos'>
				<Entrada />
			</Route>
		</Switch>
	)
}
import React, { useEffect } from 'react'
import Drawer from './Drawer'
import MisCursos from './MisCursos'
import Cursos from './Cursos'
import { makeStyles } from '@material-ui/core/styles'
import { Switch, Route, Redirect } from 'react-router-dom'
import Asignar from './Asignar'
import Grupos from './Grupos'
import { useSelector, useDispatch } from 'react-redux'
import { hasPermission, permisos } from '../../constants/permisos'
import { Backdrop, CircularProgress } from '@material-ui/core'
import Configuracion from './Configuracion'
import { urlPermisos } from '../../api/urls'
import { apiGet } from '../../api'
import { setPermisos } from '../../actions/permisos/setPermisos'
import { openSnack } from '../../actions/feedback/openSnack'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: '100%'
	},
	content: {
		flexGrow: 1,
		//  height: '100%',
		padding: theme.spacing(3),
        maxWidth: drawerWidth => 'calc(100vw - '+drawerWidth+'px)'
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar
	},
	backdrop: {
		zIndex: 1600,
		color: '#fff'
	}
}))

export default function Dashboard() {
    const drawerWidth = useSelector(state => state.feedback.drawerWidth)
	const classes = useStyles(drawerWidth)
	const loading = useSelector(state => state.feedback.loading)
	const infoPermisos = useSelector(state => state.permisos)
	const rol = useSelector(state => state.login.rol)

	const dispatch = useDispatch()

	useEffect(() => {
		apiGet(urlPermisos).then(r => {
			if (r.status === 'OK') dispatch(setPermisos(r.data))
			else dispatch(openSnack({texto: 'Algo salio mal, por favor actualice la página', tipo: 'error'}))
		})
	}, [dispatch])

		return (
			<div className={classes.root}>
				<Drawer />
				<Backdrop className={classes.backdrop} open={Boolean(loading)}>
					<CircularProgress color='inherit' />
				</Backdrop>
				<main className={classes.content}>
					<div className={classes.toolbar} />
					<div style={{ height: 'calc(100% - 64px)' }}>
						<Switch>
							<Route path='/dashboard/cursos'>
								<Cursos />
							</Route>
							<Route path='/dashboard/miscursos'>
								<MisCursos />
							</Route>
                            {/* && false para deshabilitar la opcion en ayuntamiento, es una funcionalidad de solo escuelas */}
							{hasPermission(rol, infoPermisos, permisos.ASIGNAR_USUARIO_CURSO) && false ? (
								<Route path='/dashboard/asignar'>
									<Asignar />
								</Route>
							) : null}
							{hasPermission(rol, infoPermisos, permisos.VER_GRUPOS) && false ? (
								<Route path='/dashboard/grupos'>
									<Grupos />
								</Route>
							) : null}
							{hasPermission(rol, infoPermisos, permisos.VER_USUARIOS) ? (
								<Route path='/dashboard/administrar'>
									<Configuracion />
								</Route>
							) : null}
							<Route path='/dashboard'>
								<Redirect to='/dashboard/cursos' />
							</Route>
							<Route path='/'>
								<Redirect to='/dashboard/cursos' />
							</Route>
						</Switch>
					</div>
				</main>
			</div>
		)
}


import { blue, green, indigo, orange, pink, purple, red, teal } from '@material-ui/core/colors'
import {
	CodeRounded,
	HelpOutlineRounded,
	InsertDriveFileRounded,
	InsertPhotoRounded,
	LinkRounded,
    LiveHelp,
	PublishRounded,
	SpellcheckRounded,
	SubjectRounded,
	VideoCallRounded
} from '@material-ui/icons'

export default [
	{ label: 'Texto', nombre: 'Texto', icono: SubjectRounded, color: green, obligatorio: false },
	{ label: 'Archivo', nombre: 'Archivo', icono: InsertDriveFileRounded, color: red, obligatorio: false },
	{ label: 'Imagen', nombre: 'Imagen', icono: InsertPhotoRounded, color: orange, obligatorio: false },
	{ label: 'Hipervinculo', nombre: 'Hipervinculo', icono: LinkRounded, color: blue, obligatorio: false },
	{ label: 'Video', nombre: 'Video', icono: VideoCallRounded, color: blue, obligatorio: false },
	{ label: 'Pregunta libre', nombre: 'Pregunta libre', color: pink, icono: LiveHelp, obligatorio: true },
	{ label: 'Pregunta de selección multiple', nombre: 'Pregunta multiple choice', icono: HelpOutlineRounded, color: purple, obligatorio: true },
	{ label: 'Codigo', nombre: 'Codigo', icono: CodeRounded, color: teal, obligatorio: false },
	{ label: 'Completar la frase', nombre: 'Completar la frase', icono: SpellcheckRounded, color: indigo, obligatorio: true },
	//{ label: '', nombre: 'Subir actividad resuelta', icono: PublishRounded, color: pink, obligatorio: true },
]

// En este archivo se listan los nombres de los modulos activos

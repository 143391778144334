import React, { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Typography
} from '@material-ui/core'
import { apiGetWithParams, apiPost } from '../../../api'
import { urlCurso, urlDivisiones } from '../../../api/urls'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { openSnack } from '../../../actions/feedback/openSnack'
import { useDispatch } from 'react-redux'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
	descripcion: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	imagen: {
		width: 150,
		height: 150,
		/* los siguientes valores son independientes del tamaño del círculo */
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50%',
		borderRadius: '50%',
		backgroundSize: '100% auto',
		border: '1px solid ' + grey['300']
	}
}))

export default function VerCursoDialog(props) {
	const { curso, onClose, open, alumnoId, reload, idiomas, niveles } = props
	const classes = useStyles()
	const [ cursosSugeridos, setCursosSugeridos ] = useState([])
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(startLoading())
		apiGetWithParams(urlCurso, { ids: curso.idsSugeridos }).then(r => {
			if (r.status === 'OK') setCursosSugeridos(r.data)
			else dispatch(openSnack({ texto: 'error al obtener informacion sobre cursos sugeridos', tipo: 'error' }))
			dispatch(endLoading())
		})
	}, [])

	const obtenerCurso = () => {
		dispatch(startLoading())
		apiPost(urlDivisiones, { cursoId: curso.id, alumnoId }).then(r => {
			if (r.status === 'OK') {
				dispatch(openSnack({ texto: 'Agregado a Mis Cursos', tipo: 'success' }))
				reload()
				onClose()
			} else dispatch(openSnack({ texto: 'Error al obtener curso', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	let idioma = ''
	let nivel = ''
	niveles.map(e => {
		if (e.id === curso.nivel) nivel = e.nombre
	})
	idiomas.map(e => {
		if (e.id === curso.idioma) idioma = e.nombre
	})

	return (
		<Dialog open={open} scroll='paper' maxWidth='md' onClose={onClose}>
			<DialogTitle disableTypography className={classes.dialogContent}>
				<img
					className={classes.imagen}
					src={curso.icono}
					alt={'imagen del curso ' + curso.nombre}
					height='40%'
					width='40%'
				/>
				<Typography variant='h5'>
					{curso.tematicaNombre} - {curso.nombre}
				</Typography>
			</DialogTitle>
			<DialogContent dividers className={classes.dialogContent}>
				<Box className={classes.descripcion}>
					<Typography paragraph>{curso.breveDescripcion}</Typography>
					<Typography variant='h6'>Información sobre este curso</Typography>
					{curso.autoasistido ? <Typography>-Curso autoasistido</Typography> : null}
					<Typography>-Nivel de dificultad: {nivel}</Typography>
					<Typography>Idioma: {idioma}</Typography>
					<Typography paragraph>-Duración estimada {curso.duracion} hs</Typography>
					{cursosSugeridos.length > 0 ? (
						<React.Fragment>
							<Typography variant='h6'>Cursos sugeridos:</Typography>
							{cursosSugeridos.map((e, i) => (
								<Typography key={i}>
									-{e.nombre} ({e.tematicaNombre})
								</Typography>
							))}
						</React.Fragment>
					) : null}
					{curso.cursoCorrelativo ? (
						<React.Fragment>
							<Typography variant='h6' color='secondary'>
								Curso correlativo:
							</Typography>
							<Typography paragraph>
								-{curso.cursoCorrelativo.nombre} ({curso.tematicaNombre})
							</Typography>
						</React.Fragment>
					) : null}
					<Typography variant='h6'>Conocimientos previos:</Typography>
					<Typography paragraph style={{ whiteSpace: 'pre-wrap' }}>
						{curso.conocimientosPrevios}
					</Typography>
					{curso.descripcion ? (
						<React.Fragment>
							<Typography variant='h6'>Descripcion:</Typography>
							<Typography style={{ whiteSpace: 'pre-wrap' }}>Descripcion: {curso.descripcion}</Typography>
						</React.Fragment>
					) : null}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color={'secondary'} onClick={onClose}>
					Salir
				</Button>
				<Button
					variant={curso.adquirido ? 'text' : 'contained'}
					color='primary'
					disabled={curso.adquirido}
					onClick={obtenerCurso}
				>
					{curso.adquirido ? 'Ya adquirido' : 'Obtener'}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

import React, { useState } from 'react'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography
} from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'
import { tamArchivo, validateSize } from '../../../../../constants'
import { openSnack } from '../../../../../actions/feedback/openSnack'
import { encodeBase64 } from '../../../../../api/encoding'
import { onChangeRespuestaModulo } from '../../../../../actions/modulos/onChangeRespuestaModulo'

export function SubirActividadResueltaPrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props
	return (
		<TextField
			className={classes.input}
			name='texto'
			value={principal.texto}
			variant='filled'
			onChange={({ target: { name, value } }) =>
				dispatch(
					onChangeModulo({
						name: 'formulario',
						value: { ...formulario, principal: { ...principal, [name]: value } }
					})
				)}
			label='Descripción'
			multiline
			rowsMax={10}
			rows={4}
			required
			error={moduloError && !principal.texto}
		/>
	)
}

export function SubirActividadResueltaRender(props) {
	const { modulo, classes, guardarRespuesta, responderPermiso, corregirPremiso } = props
	const [ open, setOpen ] = useState(false)
	const data = useSelector(state => state.modulos.data[modulo.id] || {})

	const dispatch = useDispatch()

	const openSelector = () => {
		document.getElementById('actividad').click()
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography paragraph>{modulo.principal.texto}</Typography>
			{modulo.respuesta? <Typography>Ya completaste esta actividad ({modulo.respuesta.respuesta.archivo})</Typography>
            :<Button variant='contained' color='primary' onClick={() => setOpen(true)}>
				Subir actividad
			</Button>}
			<Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
				<DialogTitle disableTypography>
					<Typography variant='h6'>Subir actividad</Typography>
				</DialogTitle>
				<DialogContent dividers>
					<Box>
						<Button onClick={openSelector} color='primary' variant='outlined'>
							Seleccionar archivo
							<input
								accept='.doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx'
								style={{ display: 'none' }}
								id='actividad'
								type='file'
								onChange={({ target: { files } }) => {
									if (files[0]) {
										const file = files[0]
										if (validateSize(files[0], tamArchivo * 1024 * 1024)) {
											encodeBase64(file, f =>
												dispatch(
													onChangeRespuestaModulo({
														name: modulo.id,
														value: { archivo: file.name, encoded: f.target.result }
													})
												)
											)
										} else
											dispatch(
												openSnack({
													texto:
														'El tamaño del documento no pude superar ' + tamArchivo + 'mb',
													tipo: 'info'
												})
											)
									}
								}}
							/>
						</Button>
						<Typography style={{marginLeft: 10}} variant='caption'>{data.archivo}</Typography>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant='text' color='secondary' onClick={() => setOpen(false)}>
						Cancelar
					</Button>
					<Button
						variant='contained'
						color='primary'
						disabled={!data.archivo}
						onClick={() => {
                            guardarRespuesta({ moduloId: modulo.id, data: { respuesta: data } })
                            setOpen(false)
                        }}
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export const subirActividadResueltaValidar = principal => (principal.texto ? true : false)

import React, { useState, useEffect } from 'react'
import {
	makeStyles,
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	DialogActions,
	Divider,
	TextField,
	MenuItem
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import GenericTable from '../../GenericTable'
import { apiGetWithParams, apiGet, apiPost, apiPut, apiDelete } from '../../../api'
import { urlUsuarios, urlRoles } from '../../../api/urls'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { hasPermission, permisos } from '../../../constants/permisos'
import { Fragment } from 'react'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.getContrastText(theme.palette.primary.main)
		}
	},
	selectedItem: {
		borderRadius: 4,
		color: theme.palette.primary.main
	},
	listItem: {
		color: theme.palette.type === 'dark' ? 'white' : 'rgba(0,0,0,0.80)',
		borderRadius: 4
	},
	textField: {
		margin: '5px 0px'
	},
	nuevoButton: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: theme.palette.success.dark,
			color: theme.palette.getContrastText(theme.palette.primary.main)
		},
		marginRight: 15
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		padding: 20
	},
	input: {
		marginBottom: 10
	}
}))

const cols = [
	{ label: 'Nombre de usuario', id: 'username', disablePadding: false, search: true },
	{ label: 'Nombre', id: 'first_name', disablePadding: false, search: true },
	{ label: 'Apellido', id: 'last_name', disablePadding: false, search: true },
	{ label: 'DNI', id: 'dni', numeric: true, disablePadding: false, search: true },
	{ label: 'Rol', id: 'rol', disablePadding: false },
	{ label: 'Fecha de nacimiento', id: 'fecha_nacimiento', disablePadding: false },
	{ label: 'Mail', id: 'email', disablePadding: false, search: true }
]

export default function Users(props) {
	const { search } = props
	const classes = useStyles()
	const [ state, setState ] = useState({
		username: '',
		contrasenia: '',
		contrasenia2: '',
		first_name: '',
		last_name: '',
		email: '',
		tipouser: '',
		nombreRol: '',
		dni: '',
		fecha_nacimiento: new Date(),
		roles: [],
		usuarioError: false,
		data: [],
		open: false,
		edit: false,
		del: false,
		ver: false
	})
	const [ actiDel, setActiDel ] = useState({ activar: false, row: [] })
	const [ mostrarRol, setMostrarRol ] = useState({ nombre: 'todos' })
	const [ tipo, setTipo ] = useState('activos')
	const [ getUsers, setGetUsers ] = useState(0)
	const infoPermisos = useSelector(state => state.permisos)
	const rol = useSelector(state => state.login.rol)

	const dispatch = useDispatch()

	useEffect(
		() => {
			apiGetWithParams(urlUsuarios, {}).then(r => {
				if (r.status === 'OK') setState(ps => ({ ...ps, data: r.data }))
				else dispatch(openSnack({ texto: 'Error al obtener la lista de usuarios', tipo: 'error' }))
			})
		},
		[ dispatch, tipo, rol, getUsers ]
	)

	useEffect(
		() => {
			dispatch(startLoading())
			apiGet(urlRoles).then(r => {
				if (r.status === 'OK') setState(ps => ({ ...ps, roles: r.data }))
				else dispatch(openSnack({ texto: 'Error al obtener la lista de roles', tipo: 'Error' }))
				dispatch(endLoading())
			})
			// eslint-disable-next-line
		},
		[ dispatch, setState ]
	)

	const onCrearUsuario = () => {
		const {
			username,
			first_name,
			last_name,
			contrasenia,
			email,
			tipouser,
			dni,
			fecha_nacimiento,
			contrasenia2,
			edit,
			id
		} = state
		if (username && first_name && last_name && email && dni && fecha_nacimiento && tipouser)
			if (contrasenia === contrasenia2) {
				dispatch(startLoading())
				const api = edit ? apiPut : apiPost
				api(urlUsuarios, {
					id,
					username,
					first_name,
					last_name,
					contrasenia,
					email,
					tipouser,
					dni,
					fecha_nacimiento: format(fecha_nacimiento, 'dd-MM-yyyy')
				}).then(r => {
					if (r.status === 'OK') {
						setGetUsers(getUsers + 1)
						cleanForm()
						dispatch(openSnack({ texto: 'Usuario guardado con éxito', tipo: 'success' }))
					} else if (r.status === 'ALREADY_EXISTS')
						dispatch(openSnack({ texto: 'Ya existe un usuario con ese DNI', tipo: 'warning' }))
					else dispatch(openSnack({ texto: 'Error al guardar usuario', tipo: 'error' }))
					dispatch(endLoading())
				})
			} else dispatch(openSnack({ texto: 'Las contraseñas no coinciden', tipo: 'info' }))
		else {
			dispatch(openSnack({ texto: 'Falta completar uno o mas campos obligatorios', tipo: 'info' }))
			setState(ps => ({ ...ps, usuarioError: true }))
		}
	}

	const eliminar = () => {
		apiDelete(urlUsuarios, { id: state.id }).then(r => {
			if (r.status === 'OK') {
				setGetUsers(getUsers + 1)
				cleanForm()
				dispatch(openSnack({ texto: 'Usuario eliminado con éxito', tipo: 'success' }))
			} else dispatch(openSnack({ texto: 'Error al eliminar usuario', tipo: 'error' }))
		})
	}

	const cleanForm = () => {
		setState({
			...state,
			username: '',
			contrasenia: '',
			contrasenia2: '',
			first_name: '',
			last_name: '',
			email: '',
			tipouser: '',
			nombreRol: '',
			dni: '',
			id: undefined,
			nombreCompleto: '',
			fecha_nacimiento: new Date(),
			usuarioError: false,
			open: false,
			edit: false,
			del: false,
			ver: false
		})
	}

	const parseDate = fecha => {
		if (!fecha) return new Date()
		const [ y, m, d ] = fecha.split('-')
		return new Date(y, m - 1, d)
	}

	const cleanRowInfo = () => {
		//setRowInfo({ activar: false, edit: false, del: false, row: {}, error: false })
	}

	let menuOptions = []

	let rowOptions = []

	if (hasPermission(rol, infoPermisos, permisos.EDITAR_USUARIO))
		rowOptions.push({
			label: 'Editar',
			action: e =>
				setState(ps => ({
					...ps,
					edit: true,
					del: false,
					ver: false,
					...e,
					tipouser: e.rolAcronimo,
					nombreRol: e.rol,
					fecha_nacimiento: parseDate(e.fecha_nacimiento)
				}))
		})
	if (hasPermission(rol, infoPermisos, permisos.ELIMINAR_USUARIO))
		rowOptions.push({
			label: 'Eliminar',
			action: e =>
				setState(ps => ({
					...ps,
					edit: false,
					del: true,
					ver: false,
					...e,
					tipouser: e.rolAcronimo,
					nombreRol: e.rol,
					fecha_nacimiento: parseDate(e.fecha_nacimiento)
				}))
		})

	const menuDeletedOptions = [
		{ label: 'Activar', action: selectedIds => setActiDel({ activar: true, row: selectedIds }) }
	]
	const rowDeletedOptions = [ { label: 'Activar', action: row => setActiDel({ activar: true, row: [ row.id ] }) } ]

	const {
		data,
		username,
		contrasenia,
		contrasenia2,
		first_name,
		last_name,
		email,
		tipouser,
		nombreRol,
		dni,
		fecha_nacimiento,
		roles,
		usuarioError,
		open,
		edit,
		del,
		ver,
		nombreCompleto
	} = state

	return (
		<Box className={classes.root}>
			<Box display='flex' alignItems='center' marginBottom='15px'>
				<Button
					className={classes.nuevoButton}
					variant='contained'
					onClick={() => setState(ps => ({ ...ps, open: true }))}
				>
					Nuevo
				</Button>
				{/* <ButtonGroup style={{ marginRight: 15 }} color='primary' aria-label='outlined primary button group'>
					<Button
						onClick={() => setTipo('activos')}
						className={tipo === 'activos' ? classes.selected : undefined}
					>
						Activos
					</Button>
					<Button
						onClick={() => setTipo('eliminados')}
						className={tipo === 'eliminados' ? classes.selected : undefined}
					>
						Eliminados
					</Button>
				</ButtonGroup> */}
				{/* <ButtonGroup color='primary' aria-label='outlined primary button group'>
					<Button
						onClick={() => setMostrarRol({ nombre: 'todos' })}
						className={mostrarRol.nombre === 'todos' ? classes.selected : undefined}
					>
						Todos
					</Button>
					{roles.map((r, i) => (
						<Button
							key={'butt-' + i}
							onClick={() => setMostrarRol(r)}
							className={mostrarRol.nombre === r.nombre ? classes.selected : classes.notSelected}
						>
							{r.acronimo}
						</Button>
					))}
				</ButtonGroup> */}
			</Box>
			<GenericTable
				search={search}
				cols={cols}
				data={data}
				menuOptions={tipo === 'eliminados' ? menuDeletedOptions : menuOptions.length > 0 ? menuOptions : null}
				rowOptions={tipo === 'eliminados' ? rowDeletedOptions : rowOptions.length > 0 ? rowOptions : null}
			/>
			{hasPermission(rol, infoPermisos, permisos.CREAR_USUARIO) ? (
				<Dialog open={open || edit || del || ver} fullWidth maxWidth='sm'>
					<DialogTitle>
						{open ? 'Nuevo' : edit ? 'Editar' : del ? 'Eliminar' : 'Datos de'} usuario
					</DialogTitle>
					<Divider />
					<DialogContent className={classes.card}>
						{ver ? (
							<Fragment>
								<Typography>Nombre de usuario: {username}</Typography>
								<Typography>Nombre: {nombreCompleto}</Typography>
								<Typography>DNI: {dni}</Typography>
								<Typography>Mail: {email}</Typography>
								<Typography>Fecha de nacimineto: {format(fecha_nacimiento, 'dd/MM/yyyy')}</Typography>
								<Typography>Rol: {nombreRol}</Typography>
							</Fragment>
						) : del ? (
							<Typography>¿Está seguro que desea eliminar a {nombreCompleto}?</Typography>
						) : (
							<Fragment>
								<TextField
									className={classes.input}
									name='username'
									label='Nombre de usuario'
									onChange={({ target: { name, value } }) =>
										setState(ps => ({ ...ps, [name]: value }))}
									value={username}
									variant='filled'
									required
									fullWidth
									error={usuarioError && !username}
								/>
								<TextField
									className={classes.input}
									name='first_name'
									label='Nombre'
									onChange={({ target: { name, value } }) =>
										setState(ps => ({ ...ps, [name]: value }))}
									value={first_name}
									variant='filled'
									required
									fullWidth
									error={usuarioError && !first_name}
								/>
								<TextField
									className={classes.input}
									name='last_name'
									label='Apellido'
									onChange={({ target: { name, value } }) =>
										setState(ps => ({ ...ps, [name]: value }))}
									value={last_name}
									variant='filled'
									required
									fullWidth
									error={usuarioError && !last_name}
								/>
								<TextField
									className={classes.input}
									name='email'
									label='Mail'
									onChange={({ target: { name, value } }) =>
										setState(ps => ({ ...ps, [name]: value }))}
									value={email}
									variant='filled'
									required
									fullWidth
									error={usuarioError && !email}
								/>
								<TextField
									className={classes.input}
									name='dni'
									label='DNI'
									onChange={({ target: { name, value } }) =>
										setState(ps => ({ ...ps, [name]: value }))}
									value={dni}
									variant='filled'
									type='number'
									required
									fullWidth
									error={usuarioError && !dni}
								/>
								<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
									<DatePicker
										className={classes.input}
										inputVariant='filled'
										label='Fecha de nacimiento'
										format='dd/MM/yyyy'
										value={fecha_nacimiento}
										maxDate={new Date()}
										onChange={e => setState(ps => ({ ...ps, fecha_nacimiento: e }))}
										cancelLabel='Atrás'
										okLabel='Seleccionar'
										color='primary'
										fullWidth
										invalidDateMessage='Seleccione una fecha valida'
									/>
								</MuiPickersUtilsProvider>
								<TextField
									className={classes.input}
									name='tipouser'
									label='ROL'
									onChange={({ target: { name, value } }) =>
										setState(ps => ({ ...ps, [name]: value }))}
									value={tipouser}
									variant='filled'
									select={true}
									required
									fullWidth
									error={usuarioError && !tipouser}
								>
									{roles.map((r, i) => (
										<MenuItem key={i} value={r.acronimo}>
											{r.nombre}
										</MenuItem>
									))}
								</TextField>
								{edit ? <Divider /> : null}
								{edit ? (
									<Typography paragraph>
										Cambiar contraseña (No completar para mantener la contraseña actual)
									</Typography>
								) : null}
								<TextField
									className={classes.input}
									name='contrasenia'
									label='Contraseña'
									onChange={({ target: { name, value } }) =>
										setState(ps => ({ ...ps, [name]: value }))}
									value={contrasenia}
									variant='filled'
									type='password'
									required={!edit}
									fullWidth
									error={usuarioError && !contrasenia}
								/>
								<TextField
									className={classes.input}
									name='contrasenia2'
									label='Confirmar contraseña'
									onChange={({ target: { name, value } }) =>
										setState(ps => ({ ...ps, [name]: value }))}
									value={contrasenia2}
									variant='filled'
									type='password'
									required={!edit}
									fullWidth
									error={usuarioError && !contrasenia2}
								/>
							</Fragment>
						)}
					</DialogContent>
					<Divider />
					<DialogActions>
						<Button variant='text' onClick={() => cleanForm()}>
							Cancelar
						</Button>
						{ver || del ? (
							<Button
								variant='outlined'
								color='secondary'
								onClick={() =>
									del ? eliminar() : setState(ps => ({ ...ps, del: true, ver: false, edit: false }))}
							>
								Eliminar
							</Button>
						) : null}
						{ver ? (
							<Button
								variant='contained'
								color='primary'
								onClick={() => setState(ps => ({ ...ps, edit: true, del: false, ver: false }))}
							>
								Editar
							</Button>
						) : null}
						{open || edit ? (
							<Button
								className={classes.guardarButton}
								variant='contained'
								onClick={onCrearUsuario}
								color='primary'
							>
								Guardar
							</Button>
						) : null}
					</DialogActions>
				</Dialog>
			) : null}
		</Box>
	)
}

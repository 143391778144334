import React, { useEffect } from 'react'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document/build/ckeditor'

import './text.css'

export function TextoPrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()

	useEffect(() => {
		DecoupledEditor.create(document.querySelector('.document-editor__editable'), {
			initialData: principal.texto,
			toolbar: [
				'undo',
				'redo',
				'|',
				'fontSize',
				'fontFamily',
				'fontColor',
				'fontBackgroundColor',
				'bold',
				'italic',
				'strikethrough',
				'underline',
				'link',
				'blockQuote',
				'alignment',
				'numberedList',
				'bulletedList',
				'|',
				'insertTable',
				'tableColumn',
				'tableRow',
				'mergeTableCells'
			]
		})
			.then(editor => {
				const toolbarContainer = document.querySelector('.document-editor__toolbar')

				toolbarContainer.appendChild(editor.ui.view.toolbar.element)
				window.editor = editor
				editor.model.document.on('change:data', () => {
					const data = window.editor.getData()
					dispatch(
						onChangeModulo({
							name: 'formulario',
							value: { ...formulario, principal: { ...principal, texto: data } }
						})
					)
				})
			})
			.catch(err => {
				console.error(err)
			})
	}, [])

	return (
		<div className='document-editor'>
			<div className='document-editor__toolbar' />
			<div className='document-editor__editable-container'>
				<div className='document-editor__editable' style={{ minHeight: '50vh' }}>
					
				</div>
			</div>
		</div>
	)
}

export function TextoRender(props) {
    const { modulo } = props
    /* useEffect(() => {
		DecoupledEditor.create(document.querySelector('.document-editor__editable'+modulo.id), {
			initialData: '',
			toolbar: []
		})
			.then(editor => {
				const toolbarContainer = document.querySelector('.document-editor__toolbar')

				toolbarContainer.appendChild(editor.ui.view.toolbar.element)
				window.editor = editor
				editor.model.document.on('change:data', () => {
					const data = window.editor.getData()
					dispatch(
						onChangeModulo({
							name: 'formulario',
							value: { ...formulario, principal: { ...principal, texto: data } }
						})
					)
				})
			})
			.catch(err => {
				console.error(err)
			})
	}, []) */
	return (
		<CKEditor
            editor={DecoupledEditor}
            data={modulo.principal.texto}
            disabled
		/>
	)
}

export const textoValidar = principal => (principal.texto ? true : false)

import React, { useEffect, useState } from 'react'
import {
	Box,
	makeStyles,
	ListItem,
	ListItemText,
	List,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	DialogActions,
	ListItemSecondaryAction,
	IconButton,
	Divider
} from '@material-ui/core'
import DeleteRounded from '@material-ui/icons/DeleteRounded'
import { apiGet, apiPost, apiPut, apiDelete, apiGetWithParams } from '../../../api'
import { urlRoles } from '../../../api/urls'
import { useDispatch, useSelector } from 'react-redux'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import TransferList from '../../TransferList'
import { hasPermission, permisos, permisosString } from '../../../constants/permisos'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%'
	},
	roleList: {
		flex: 1,
		marginRight: 15
	},
	transferWrapper: {
		flex: 4,
		display: 'flex',
		flexDirection: 'column'
	},
	selectedItem: {
		borderRadius: 4,
		color: theme.palette.primary.main
	},
	listItem: {
		color: theme.palette.type === 'dark' ? 'white' : 'rgba(0,0,0,0.54)',
		borderRadius: 4
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	editButton: {
		marginTop: 15,
		marginLeft: 'auto'
	},
	newButton: {
		marginTop: 10
	}
}))

export default function Roles() {
	const classes = useStyles()
	const [ doFetch, setDoFetch ] = useState(0)
	const [ roles, setRoles ] = useState([])
	const [ openNewRol, setOpenNewRol ] = useState({ open: false, name: '', acronym: '' })
	const [ permisosDisponibles, setPermisosDisponibles ] = useState([])
	const [ rolActual, setRolActual ] = useState({})
	const infoPermisos = useSelector(state => state.permisos)
	const rol = useSelector(state => state.login.rol)

	const dispatch = useDispatch()

	useEffect(
		() => {
			dispatch(startLoading())
			apiGet(urlRoles).then(r => {
				if (r.status === 'OK') setRoles(r.data)
				else dispatch(openSnack({ texto: 'Error al obtener la lista de roles', tipo: 'error' }))
				dispatch(endLoading())
			})
		},
		[ setRoles, dispatch, doFetch ]
	)

	const setRolPermisos = permisos => {
		setRolActual({ ...rolActual, permisos })
	}

	const onCrearRol = () => {
		dispatch(startLoading())
		apiPost(urlRoles, { nombre: openNewRol.nombre, acronimo: openNewRol.acronimo }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch + 1)
				dispatch(openSnack({ texto: 'Rol creado con éxito', tipo: 'success' }))
				setOpenNewRol({ open: false, nombre: '', acronym: '' })
			} else if (r.status === 'ALREADY_EXISTS')
				dispatch(openSnack({ texto: 'Nombre o acrónimo ya existente', tipo: 'error' }))
			else dispatch(openSnack({ texto: 'Error al crear rol', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const onEditRol = () => {
		dispatch(startLoading())
		apiPut(urlRoles, { ...rolActual }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch - 1)
				dispatch(openSnack({ texto: 'Rol modificado con éxito', tipo: 'success' }))
				setRolActual({})
			} else if (r.status === 'ALREADY_EXISTS')
				dispatch(openSnack({ texto: 'Nombre o acrónimo ya existente', tipo: 'error' }))
			else dispatch(openSnack({ texto: 'Error al modificar rol', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const onDelRol = r => {
		dispatch(startLoading())
		apiDelete(urlRoles, { rolId: r.id }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch + 1)
				dispatch(openSnack({ texto: 'Rol eliminado con éxito', tipo: 'success' }))
				setRolActual({})
			} else dispatch(openSnack({ texto: 'Error al eliminar rol', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.roleList}>
				<List style={{ padding: 0 }}>
					{roles.map(r => (
						<ListItem
							button
							key={'li-' + r.id}
							selected={rolActual.id === r.id}
							className={rolActual.id === r.id ? classes.selectedItem : classes.listItem}
							onClick={() => {
								if (hasPermission(rol, infoPermisos, permisos.MODIFICAR_ROL) === -1) return
                                setPermisosDisponibles(permisosString.filter(p => r.permisos.indexOf(p) === -1))
                                console.log(r)
								setRolActual(r)
							}}
						>
							<ListItemText key={'lit-' + r.id} primary={r.nombre} />
							{r.acronimo.toUpperCase() !== 'TG' &&
							hasPermission(rol, infoPermisos, permisos.ELIMINAR_ROL) !== -1 ? (
								<ListItemSecondaryAction key={'lisa-' + r.id}>
									<IconButton
										key={'ib-' + r.id}
										color='secondary'
										onClick={() => setRolActual({ ...r, eliminar: true })}
									>
										<DeleteRounded key={'dr-' + r.id} />
									</IconButton>
								</ListItemSecondaryAction>
							) : null}
						</ListItem>
					))}
					{hasPermission(rol, infoPermisos, permisos.CREAR_ROL) !== -1 ? (
						<Button
							className={classes.newButton}
							variant='outlined'
							color='primary'
							onClick={() => {
								setRolActual({})
								setOpenNewRol({ open: true, name: '', acronym: '' })
							}}
							fullWidth
						>
							Nuevo rol
						</Button>
					) : null}
				</List>
			</Box>
			<Box className={classes.transferWrapper}>
				{rolActual.nombre ? (
					<React.Fragment>
						<Typography paragraph>Editar rol</Typography>
						<TextField
							margin='dense'
							variant='filled'
							name='nombre'
							value={rolActual.nombre}
							label='Nombre'
							onChange={({ target: { name, value } }) => setRolActual({ ...rolActual, [name]: value })}
						/>
						<TextField
							margin='dense'
							variant='filled'
							name='acronimo'
							value={rolActual.acronimo}
							label='Acrónimo'
							onChange={({ target: { name, value } }) =>
								value.length > 4 ? '' : setRolActual({ ...rolActual, [name]: value })}
							helperText='Nombre abreviado del rol, por ejemplo: Alumno - AL'
						/>
						<TransferList
							left={rolActual.permisos}
							right={permisosDisponibles}
							setLeft={setRolPermisos}
							setRight={setPermisosDisponibles}
							leftName='Permisos asignados'
							rightName='Disponibles'
						/>
						<Button className={classes.editButton} variant='contained' color='primary' onClick={onEditRol}>
							Guardar cambios
						</Button>
					</React.Fragment>
				) : (
					<Typography>
						{hasPermission(rol, infoPermisos, permisos.MODIFICAR_ROL) !== -1 ? (
							'Seleccione un rol para modificar'
						) : (
							'No tiene permisos para editar los roles'
						)}
					</Typography>
				)}
			</Box>
			<Dialog open={openNewRol.open || rolActual.eliminar}>
				<DialogTitle>{rolActual.eliminar ? 'Eliminar rol' : 'Nuevo Rol'}</DialogTitle>
				<Divider />
				<DialogContent className={classes.content}>
					{rolActual.eliminar ? (
						<Typography>¿Está seguro que desea eliminar el rol {rolActual.name}?</Typography>
					) : (
						<React.Fragment>
							<TextField
								margin='dense'
								name='nombre'
								value={openNewRol.nombre}
								onChange={e => setOpenNewRol({ ...openNewRol, nombre: e.target.value })}
								label='Nombre'
								variant='filled'
								fullWidth
							/>
							<TextField
								margin='dense'
								name='acronimo'
								value={openNewRol.acronimo}
								onChange={e =>
									e.target.value.length > 4
										? ''
										: setOpenNewRol({ ...openNewRol, acronimo: e.target.value })}
								label='Acrónimo'
								variant='filled'
								helperText='Nombre abreviado del rol, por ejemplo: Alumno - AL'
								fullWidth
							/>
						</React.Fragment>
					)}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						color='secondary'
						onClick={() => {
							setRolActual({ ...rolActual, eliminar: false })
							setOpenNewRol({ open: false, name: '' })
						}}
					>
						Salir
					</Button>
					<Button
						color='primary'
						variant='contained'
						onClick={rolActual.eliminar ? () => onDelRol(rolActual) : onCrearRol}
					>
						{rolActual.eliminar ? 'Confirmar' : 'Crear'}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}

import { combineReducers } from 'redux'
import { login } from './login'
import { curso } from './curso'
import { unidad } from './unidad'
import { usuarios } from './usuarios'
import { grupos } from './grupos'
import { permisos } from './permisos'
import { feedback } from './feedback'
import { metadata } from './metadata'
import { modulos } from './modulos'
import { theme } from './theme'


export default combineReducers({
    login, 
    curso, 
    unidad,
    usuarios,
    grupos,
    permisos,
    feedback,
    metadata,
    modulos,
    theme
})

import React from 'react'
import { TextField, Typography } from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from '@material-ui/icons'

export function HipervinculoPrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props
	return (
		<TextField
			className={classes.input}
			name='link'
			value={principal.link}
			variant='filled'
			onChange={({ target: { name, value } }) =>
				dispatch(
					onChangeModulo({
						name: 'formulario',
						value: { ...formulario, principal: { ...principal, [name]: value } }
					})
				)}
			label='Hipervínculo'
			required
			error={moduloError && !principal.link}
		/>
	)
}

export function HipervinculoRender(props) {
	const { modulo } = props
	return (
		<a
			style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: 10 }}
			href={modulo.principal.link}
			target='_blank'
			rel='noopener noreferrer'
		>
			<Link color='primary' style={{ marginRight: 10 }} />
			<Typography>{modulo.principal.link}</Typography>
		</a>
	)
}

export const hipervinculoValidar = principal => (principal.link ? true : false)

import { OPEN_SNACK, CLOSE_SNACK, START_LOADING, END_LOADING, DRAWER_WIDTH } from '../constants'

export const feedback = (state = { openSnack: false, loading: 0 }, a) => {
	switch (a.type) {
		case OPEN_SNACK:
			return { ...state, openSnack: true, ...a.payload }
		case CLOSE_SNACK:
			return { ...state, openSnack: false }
		case START_LOADING:
			return { ...state, loading: state.loading + 1 }
		case END_LOADING:
			return { ...state, loading: state.loading - 1 }
        case DRAWER_WIDTH:
            return {...state, drawerWidth: a.payload}
		default:
			return state
	}
}

import React from 'react'
import httpMedia from '../httpMedia'
import styles from './styles.module.css'

const GoTop = (props) => {

    const goTop = () => {
        window.scrollTo(0, 0)
    }

    return (

        <div className={styles.gotop_Btn} onClick={() => goTop()}>
            <img src={`${httpMedia}065-right-arrow-1.svg`} alt="" />
        </div>

    )
}

export default GoTop

import React, { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Fab,
	IconButton,
	InputAdornment,
	makeStyles,
	TextField,
	Typography
} from '@material-ui/core'
import { apiDelete, apiGetWithParams } from '../../../api'
import { urlCurso } from '../../../api/urls'
import { openSnack } from '../../../actions/feedback/openSnack'
import { useDispatch, useSelector } from 'react-redux'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import CursoItem from './CursoItem'
import { permisos, hasPermission } from '../../../constants/permisos'
import { Add, AddRounded, RemoveRounded, SearchRounded } from '@material-ui/icons'
import CrearCursoDialog from './CrearCursoDialog'
import VerCursoDialog from './VerCursoDialog'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '0px 15px'
	},
	cursosContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	addButton: {
		position: 'fixed',
		bottom: theme.spacing(3),
		right: theme.spacing(3),
		zIndex: 1000
	},
	buscar: {
		borderRadius: 90,
		padding: 12
	},
	tematicaContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	tematicaTitle: {
		marginLeft: 15,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	}
}))

export default function Cursos() {
	const classes = useStyles()
	const [ open, setOpen ] = useState({ show: false, editar: false, cursoActual: {} })
	const [ verCurso, setVerCurso ] = useState({ open: false, curso: {} })
	const [ eliminarOpen, setEliminarOpen ] = useState({ show: false, cursoActual: {} })
	const [ cursos, setCursos ] = useState([])
	const [ idiomas, setIdiomas ] = useState([])
	const [ niveles, setNiveles ] = useState([])
	const [ doFetch, setDoFetch ] = useState(0)
	const { id, rol } = useSelector(state => state.login)
	const [ closed, setClosed ] = useState([])
	const [ search, setSearch ] = useState('')
	const infoPermisos = useSelector(state => state.permisos)

	const dispatch = useDispatch()

	useEffect(
		() => {
			dispatch(startLoading())
			apiGetWithParams(urlCurso, { todos: true }).then(r => {
				if (r.status === 'OK') {
					let temp = []
					r.data.map(e => temp.push(...e.cursos))
					setCursos(r.data)
				} else dispatch(openSnack({ texto: 'Error al obtener los cursos', tipo: 'error' }))
				dispatch(endLoading())
            })
            dispatch(startLoading())
            apiGetWithParams(urlCurso, { metadataNuevo: true }).then(r => {
			if (r.status === 'OK') {
				setIdiomas(r.data.idiomas)
				setNiveles(r.data.dificultades)
			} else dispatch(openSnack({ texto: 'error al obtener los datos para crear curso', tipo: 'error' }))
			dispatch(endLoading())
		})
		},
		[ doFetch, dispatch, setCursos ]
	)

	const onClose = b => {
		setOpen({ show: false, editar: false, cursoActual: {} })
		if (b) setDoFetch(doFetch - 1)
	}

	const close = i => {
		setClosed([ ...closed, i ])
	}

	const expand = i => {
		const idx = closed.indexOf(i)
		setClosed([ ...closed.slice(0, idx), ...closed.slice(idx + 1) ])
	}

	const editarCurso = curso => {
		setOpen({ show: true, editar: true, cursoActual: curso })
	}

	const eliminarCurso = () => {
		dispatch(startLoading())
		apiDelete(urlCurso, { cursoId: eliminarOpen.cursoActual.id }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch - 1)
				setEliminarOpen({ show: false })
				dispatch(openSnack({ texto: 'Curso eliminado con éxito', tipo: 'success' }))
			} else dispatch(openSnack({ texto: 'Error al eliminar el curso', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const closeVerCurso = () => {
		setVerCurso({ open: false, curso: {} })
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.header}>
				<Typography color='primary' variant='h5'>
					Cursos disponibles
				</Typography>
				<TextField
					variant='outlined'
					placeholder='Buscar'
					inputProps={{
						style: { paddingTop: 12, paddingBottom: 12 }
					}}
					name='search'
					value={search}
					onChange={({ target: { value } }) => setSearch(value)}
					InputProps={{
						style: { borderRadius: 90 },
						startAdornment: (
							<InputAdornment position='start'>
								<SearchRounded color='primary' />
							</InputAdornment>
						)
					}}
				/>
			</Box>
			{cursos.map((e, i) => {
				let cursosFiltrados = []
				if (search)
					cursosFiltrados = e.cursos.filter(e => e.nombre.toLowerCase().indexOf(search.toLowerCase()) !== -1)
				else cursosFiltrados = e.cursos
				if (cursosFiltrados.length === 0) return null
				const expanded = closed.indexOf(i) === -1
				return (
					<Box key={'bx1-' + i} className={classes.tematicaContainer}>
						<Box key={'bx2-' + i} className={classes.tematicaTitle}>
							<IconButton
								onClick={() => (expanded ? close(i) : expand(i))}
								color={expanded ? 'secondary' : 'primary'}
								key={'ib1-' + i}
							>
								{expanded ? <RemoveRounded key={'rr1-' + i} /> : <AddRounded />}
							</IconButton>
							<Typography key={'t1-' + i} variant='h6'>
								{e.tematica.nombre} {cursosFiltrados.length === 0 ? '(No hay cursos para mostrar)' : ''}
							</Typography>
						</Box>
						<Collapse key={'cl1-' + i} in={expanded} timeout='auto' unmountOnExit>
							<Box key={'bx3-' + i} className={classes.cursosContainer}>
								{cursosFiltrados.map(c => (
									<CursoItem
										key={c.id}
										curso={c}
										alumnoId={id}
										reload={() => setDoFetch(doFetch + 1)}
										editar={editarCurso}
										eliminar={cursoActual => setEliminarOpen({ show: true, cursoActual })}
										verCurso={() => setVerCurso({ open: true, curso: c })}
									/>
								))}
							</Box>
						</Collapse>
					</Box>
				)
			})}
			{eliminarOpen.show ? (
				<Dialog open={eliminarOpen.show} onClose={() => setEliminarOpen({ show: false })}>
					<DialogTitle disableTypography>
						<Typography variant='h6'>Eliminar curso</Typography>
					</DialogTitle>
					<Divider />
					<DialogContent>
						<DialogContentText>
							¿Está seguro que quiere eliminar el curso "{eliminarOpen.cursoActual.nombre}"?
						</DialogContentText>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Button onClick={() => setEliminarOpen({ show: false })}>Cancelar</Button>
						<Button variant='contained' color='secondary' onClick={eliminarCurso}>
							Eliminar
						</Button>
					</DialogActions>
				</Dialog>
			) : null}
			{hasPermission(rol, infoPermisos, permisos.CREAR_CURSO) ? (
				<Fab className={classes.addButton} size='large' color='primary' onClick={() => setOpen({ show: true })}>
					<Add />
				</Fab>
			) : null}
			{open.show ? (
				<CrearCursoDialog open={open.show} onClose={onClose} editar={open.editar} curso={open.cursoActual} />
			) : null}
			{verCurso.open ? (
				<VerCursoDialog
					open={verCurso.open}
					curso={verCurso.curso}
					onClose={closeVerCurso}
					alumnoId={id}
					reload={() => setDoFetch(doFetch + 1)}
					idiomas={idiomas}
					niveles={niveles}
				/>
			) : null}
		</Box>
	)
}

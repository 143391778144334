import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'

export function CodigoPrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props
	return (
		<TextField
			className={classes.input}
			name='texto'
			value={principal.texto}
			variant='filled'
			onChange={({ target: { name, value } }) =>
				dispatch(
					onChangeModulo({
						name: 'formulario',
						value: { ...formulario, principal: { ...principal, [name]: value } }
					})
				)}
			label='Texto'
			multiline
			rowsMax={10}
			rows={4}
			required
			error={moduloError && !principal.texto}
		/>
	)
}

const useStyles = makeStyles(theme => ({
	code: {
		whiteSpace: 'pre-wrap',
		backgroundColor: theme.palette.background.level2,
		color: theme.palette.text.primary,
		padding: 5,
		border: '1px solid ' + theme.palette.background.default,
		borderRadius: 4
	}
}))

export function CodigoRender(props) {
	const { modulo } = props
    const classes = useStyles()
	return <code className={classes.code}>{modulo.principal.texto}</code>
}

export const codigoValidar = principal => (principal.texto ? true : false)

import React, { useState } from 'react';
import {
	Box,
	makeStyles,
	Typography,
	List,
	Paper,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
	Divider
} from '@material-ui/core';
import {
	GroupRounded,
	SettingsRounded,
	LocalOfferRounded
} from '@material-ui/icons';

import Users from './Users';
import Roles from './Roles';
//import ConfigWeb from './ConfigWeb';

const useStyles = makeStyles((theme) => ({
	root: {
 		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		//alignItems: 'center'
	},
	adminWrapper: {
		display: 'flex',
		width: '100%'
	},
	list: {
		flex: 1,
		marginRight: 15
	},
	selectedItem: {
		borderRadius: 4,
		color: theme.palette.primary.main
	},
	listItem: {
		color: theme.palette.type === 'dark' ? 'white' : 'rgba(0,0,0,0.54)',
		borderRadius: 4
	},
	paper: {
		flex: 4,
		marginLeft: 15,
		display: 'flex',
		flexDirection: 'column',
		padding: '30px 20px'
	}
}));

const options = [
	{ label: 'Usuarios', icon: <GroupRounded color="inherit" />, renderMethod: Users },
	/* { label: 'Configuración del sitio web', icon: <SettingsRounded color="inherit" />, renderMethod: ConfigWeb }, */
	{ label: 'Roles', icon: <LocalOfferRounded color="inherit" />, renderMethod: Roles }
];

export default function Configuracion() {
	const classes = useStyles();
	const [ selectedIndex, setSelectedIndex ] = useState(0);
	const [ search, setSearch ] = useState('');

	const renderTable = (Comp) => {
		return <Comp search={search} />;
	};

	return (
		<Box className={classes.root}>
			<Typography variant="h5" paragraph color="textPrimary">
				Configuración
			</Typography>
			<Box className={classes.adminWrapper}>
				<List className={classes.list}>
					{options.map((o, i) => (
						<ListItem
							key={'li-' + i}
							className={selectedIndex === i ? classes.selectedItem : classes.listItem}
							button
							selected={selectedIndex === i}
							onClick={(_) => {
								setSearch('');
								setSelectedIndex(i);
							}}
						>
							<ListItemIcon style={{ color: 'inherit' }} key={'lic-' + i}>
								{o.icon}
							</ListItemIcon>
							<ListItemText key={'lit-' + i} primary={o.label} />
						</ListItem>
					))}
				</List>
				<Paper className={classes.paper}>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<Typography paragraph variant="h6">
							{options[selectedIndex].label}
						</Typography>
						<Box display="flex" alignItems="center">
							{options[selectedIndex].label === 'Roles' ? null : options[selectedIndex].label ===
							'Configuración del sitio web' ? null : (
								<TextField
									name="search"
									variant="outlined"
									size="small"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									placeholder="Buscar.."
								/>
							)}
						</Box>
					</Box>
					<Divider style={{margin: '15px 0px'}} />
					{renderTable(options[selectedIndex].renderMethod)}
				</Paper>
			</Box>
		</Box>
	);
}

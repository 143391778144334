import { LOGIN, LOGOUT } from '../constants'

export const login = (state = {}, a) => {
	switch (a.type) {
		case LOGIN:
			if (a.payload.status === 'OK') localStorage.setItem('Authorization', a.payload.data.token)
			return { ...state, ...a.payload.data }

		case LOGOUT:
			localStorage.removeItem('Authorization')
			window.location.reload()
			return {}
		default:
			return state
	}
}

import { SET_USUARIOS, LOGOUT } from '../constants'

export const usuarios = (state = { lista: [] }, a) => {
	switch (a.type) {
		case SET_USUARIOS:
			return { ...state, lista: a.payload }
		case LOGOUT:
			return {}
		default:
			return state
	}
}

import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { apiPut } from '../../../api'
import { urlChangePass } from '../../../api/urls'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	}
}))

export default function CambiarContrasenia(props) {
	const { onClose, open } = props
	const classes = useStyles()
	const [ { oldPass, newPass, newPass2, error }, setPass ] = useState({
		oldPass: '',
		newPass: '',
		newPass2: '',
		error: false
	})
	const dispatch = useDispatch()

	const onConfirm = () => {
		if (newPass === newPass2){
			dispatch(startLoading())
			apiPut(urlChangePass, { oldPass, newPass }).then(r => {
				dispatch(endLoading())
				if (r.status === 'OK') {
					dispatch(openSnack({ texto: 'Contraseña modificada exitosamente', tipo: 'success' }))
					onClose()
				} else if (r.status === 'INCORRECT')
				dispatch(openSnack({ texto: 'Contraseña incorrecta', tipo: 'error' }))
				else dispatch(openSnack({ texto: 'Error al modificar contraseña', tipo: 'error' }))
			})
		}
		else {
			dispatch(openSnack({ texto: 'Las contraseñas no coinciden', tipo: 'info' }))
			setPass(ps => ({ ...ps, error: true }))
		}
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth >
			<DialogTitle>Cambiar contraseña</DialogTitle>
			<DialogContent dividers className={classes.root}>
				<TextField
					margin='dense'
					value={oldPass}
					name='oldPass'
					label='Contraseña actual'
					onChange={({ target: { name, value } }) => setPass(ps => ({ ...ps, [name]: value }))}
					required
					type='password'
					fullWidth
					variant='filled'
					error={error && !oldPass}
				/>
				<TextField
					margin='dense'
					value={newPass}
					name='newPass'
					label='Nueva contraseña'
					onChange={({ target: { name, value } }) => setPass(ps => ({ ...ps, [name]: value }))}
					required
					type='password'
					fullWidth
					variant='filled'
					error={error && newPass !== newPass2}
				/>
				<TextField
					margin='dense'
					value={newPass2}
					name='newPass2'
					label='Confirmar nueva contraseña'
					onChange={({ target: { name, value } }) => setPass(ps => ({ ...ps, [name]: value }))}
					required
					type='password'
					fullWidth
					variant='filled'
					error={error && newPass !== newPass2}
				/>
			</DialogContent>
			<DialogActions>
				<Button color='secondary' onClick={onClose}>
					Cancelar
				</Button>
				<Button variant='contained' color='primary' onClick={onConfirm}>
					Confirmar
				</Button>
			</DialogActions>
		</Dialog>
	)
}

import React from 'react'
import { Button, IconButton, TextField, Typography } from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { openSnack } from '../../../../../actions/feedback/openSnack'
import { useDispatch, useSelector } from 'react-redux'
import { encodeBase64 } from '../../../../../api/encoding'
import { tamArchivo, validateSize } from '../../../../../constants'
import { GetApp } from '@material-ui/icons'

export function ArchivoPrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props
	return (
		<div style={{ width: '100%', display: 'flex' }}>
			<TextField
				className={classes.input}
				style={{ width: '70%' }}
				name='archivo'
				value={principal.archivo ? principal.archivo : ''}
				variant='filled'
				label='Archivo'
				onChange={() => {}}
				required
				error={moduloError && !principal.archivo}
			/>
			<Button
				style={{ width: '28%', marginLeft: '2%', alignSelf: 'center', marginBottom: 10 }}
				variant='contained'
				color={moduloError && !principal.encoded ? 'error' : 'primary'}
				component='label'
			>
				Subir archivo
				<input
					style={{ display: 'none' }}
					id='path'
					type='file'
					onChange={({ target: { files } }) => {
						const file = files[0]
						if (file) {
							if (validateSize(file, tamArchivo * 1024 * 1024))
								encodeBase64(file, f =>
									dispatch(
										onChangeModulo({
											name: 'formulario',
											value: {
												...formulario,
												principal: {
													...principal,
													archivo: file.name,
													encoded: f.target.result
												}
											}
										})
									)
								)
							else
								dispatch(
									openSnack({
										texto: 'El tamaño del archivo no puede superar los ' + tamArchivo + 'mb',
										tipo: 'info'
									})
								)
						}
					}}
				/>
			</Button>
		</div>
	)
}

export function ArchivoRender(props) {
	const { modulo } = props
	return (
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
			{/* <InsertDriveFile color='primary' style={{ marginRight: 10 }} /> */}
			<Typography>{modulo.principal.archivo}</Typography>
			<IconButton
				color='primary'
				onClick={() => {
					window.open(modulo.url)
				}}
			>
				<GetApp />
			</IconButton>
		</div>
	)
}

export const archivoValidar = principal => {
	return principal.archivo && principal.encoded
}

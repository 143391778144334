import { TOGGLE_DARK, CHANGE_COLOR, SET_SETTINGS } from '../constants'
import { blue } from '@material-ui/core/colors'

export const theme = (
	state = {
		prefersDarkMode: localStorage.getItem('prefersDarkMode') === 'true',
		light: blue[500],
		dark: blue[300],
		darkChecked: true
	},
	a
) => {
	switch (a.type) {
		case TOGGLE_DARK:
			localStorage.setItem('prefersDarkMode', !state.prefersDarkMode)
			return { ...state, prefersDarkMode: !state.prefersDarkMode }
		case CHANGE_COLOR:
			const prefersDarkMode = state.prefersDarkMode && a.payload.darkChecked
			localStorage.setItem('prefersDarkMode', prefersDarkMode)
			return { ...state, ...a.payload, prefersDarkMode }
		case SET_SETTINGS:
			const { theme } = a.payload
			localStorage.setItem('prefersDarkMode', state.prefersDarkMode && (theme ? theme.darkChecked : true))
			return { ...state, ...theme, prefersDarkMode: state.prefersDarkMode && (theme ? theme.darkChecked : true) }
		default:
			return state
	}
}

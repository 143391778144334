import React, { useState } from 'react'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	MenuItem,
	TextField,
	Typography
} from '@material-ui/core'
import { ArrowBack, CloseRounded, DeleteRounded, EditRounded } from '@material-ui/icons'
import { urlTematicas } from '../../../api/urls'
import { apiDelete, apiPost, apiPut } from '../../../api'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { openSnack } from '../../../actions/feedback/openSnack'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
	dialogContent: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		overflowY: 'auto'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
}))

export default function TematicasDialog(props) {
	const classes = useStyles()
	const [ { editar, eliminar, nombre, nueva, idTematica, admin }, setState ] = useState({
		editar: false,
		eliminar: false,
		nombre: '',
		nueva: false,
		idTematica: -1,
		admin: true
	})
	const dispatch = useDispatch()

	const { open, onClose, tematicas, getTematicas } = props

	const onCrearTematica = () => {
		if (nombre) {
			dispatch(startLoading())
			apiPost(urlTematicas, { nombre: nombre }).then(r => {
				if (r.status === 'OK') {
					getTematicas()
					setState(ps => ({ ...ps, admin: true, nombre: '', nueva: false }))
					dispatch(openSnack({ texto: 'Temáctica creada con éxito', tipo: 'success' }))
				} else dispatch(openSnack({ texto: 'Error al guardar nueva tematica', tipo: 'error' }))
				dispatch(endLoading())
			})
		} else {
			dispatch(openSnack({ texto: 'El nombre no puede estar vacío', tipo: 'info' }))
		}
	}

	const onEditarTematica = () => {
		if (nombre) {
			dispatch(startLoading())
			apiPut(urlTematicas, { tematicaId: idTematica, nombre }).then(r => {
				if (r.status === 'OK') {
					getTematicas()
					setState(ps => ({ ...ps, admin: true, editar: false, nombre: '', idTematica: '' }))
					dispatch(openSnack({ texto: 'Temáctica editada con éxito', tipo: 'success' }))
				} else dispatch(openSnack({ texto: 'Error al guardar cambios', tipo: 'error' }))
				dispatch(endLoading())
			})
		} else {
			dispatch(openSnack({ texto: 'El nombre no puede estar vacío', tipo: 'info' }))
		}
	}

	const onDeleteTematica = () => {
		dispatch(startLoading())
		apiDelete(urlTematicas, { tematicaId: idTematica }).then(r => {
			if (r.status === 'OK') {
				getTematicas()
				setState(ps => ({ ...ps, admin: true, delete: false, nombre: '', idTematica: '' }))
				dispatch(openSnack({ texto: 'Temáctica eliminada con éxito', tipo: 'success' }))
			} else if (r.status === 'NOT_EMPTY')
				dispatch(
					openSnack({
						texto: 'No se puede eliminar la tematica porque tiene cursos asociados',
						tipo: 'error'
					})
				)
			else dispatch(openSnack({ texto: 'Error al guardar cambios', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const closeDialog = () => {
		setState({
			nueva: false,
			nombre: '',
			idTematica: '',
			editar: false,
			eliminar: false,
			admin: true
		})
		onClose()
	}

	return (
		<Dialog
			fullWidth={true}
			scroll='paper'
			disableBackdropClick={true}
			maxWidth={'sm'}
			open={open}
			scroll='paper'
			onClose={closeDialog}
		>
			<DialogTitle disableTypography>
				{!admin ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start'
						}}
					>
						<IconButton
							onClick={() =>
								setState(ps => ({ ...ps, admin: true, nueva: false, editar: false, eliminar: false }))}
						>
							<ArrowBack />
						</IconButton>
						<Typography variant='h6'>
							{nueva ? 'Crear' : editar ? 'Editar' : 'Eliminar'} tematica
						</Typography>
					</div>
				) : (
					<React.Fragment>
						<Typography variant='h6'>Administrar tematicas</Typography>
						<IconButton aria-label='close' className={classes.closeButton} onClick={closeDialog}>
							<CloseRounded color='error' />
						</IconButton>
					</React.Fragment>
				)}
			</DialogTitle>
			<DialogContent dividers={true}>
				{admin ? (
					<React.Fragment>
						<Button
							style={{ textTransform: 'none', fontSize: 16 }}
							fullWidth
							color='primary'
							onClick={() => setState(ps => ({ ...ps, nueva: true, admin: false }))}
						>
							Agregar
						</Button>
						{tematicas && tematicas.length ? (
							<List>
								{tematicas.map(
									(t, i) =>
										t.id < 0 ? null : (
											<ListItem style={{paddingRight:100}}>
												<ListItemText primary={t.nombre} />
												<ListItemSecondaryAction>
													<IconButton
														onClick={() =>
															setState(ps => ({
																...ps,
																editar: true,
																idTematica: t.id,
																nombre: t.nombre,
																admin: false
															}))}
													>
														<EditRounded color='primary' />
													</IconButton>
													<IconButton
														edge='end'
														onClick={() =>
															setState(ps => ({
																...ps,
																admin: false,
																eliminar: true,
																idTematica: t.id,
																nombre: t.nombre
															}))}
													>
														<DeleteRounded color='error' />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										)
								)}
							</List>
						) : (
							<Typography>No hay tematicas para mostrar</Typography>
						)}
					</React.Fragment>
				) : nueva || editar ? (
					<TextField
						className={classes.input}
						name='nombre'
						value={nombre}
						fullWidth
						variant='filled'
						onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
						label='Nombre'
						required
					/>
				) : (
					'¿Está seguro que desea eliminar la tematica ' + nombre + '?'
				)}
			</DialogContent>
			{!admin ? (
				<DialogActions>
					<Button
						color={eliminar ? 'secondary' : 'primary'}
						variant='contained'
						onClick={() => (nueva ? onCrearTematica() : editar ? onEditarTematica() : onDeleteTematica())}
					>
						{eliminar ? 'Eliminar' : 'Guardar'}
					</Button>
				</DialogActions>
			) : null}
		</Dialog>
	)
}

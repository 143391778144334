import { SET_METADATA } from '../constants'

export const metadata = (state = { nombre: 'Cursos' }, a) => {
	switch (a.type) {
		case SET_METADATA:
			if (a.payload.nombre) document.title = a.payload.nombre
			return { ...a.payload }
		default:
			return state
	}
}

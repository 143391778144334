import { ONCHANGE_MODULO, CLEAN_MODULOS, ONCHANGE_RESPUESTA_MODULO } from '../constants'

export const modulos = (
	state = {
		editing: {
			openNew: false,
			openDelete: false,
			paraEliminar: {},
			pagina: 1,
			titulo: '',
			moduloError: false,
			formulario: { obligatorio: false, principal: {} }
		},
		data: {}
	},
	a
) => {
	switch (a.type) {
		case ONCHANGE_MODULO:
			return { ...state, editing: { ...state.editing, [a.payload.name]: a.payload.value } }
		case ONCHANGE_RESPUESTA_MODULO:
			return { ...state, data: { ...state.data, [a.payload.name]: a.payload.value } }
		case CLEAN_MODULOS:
			return {
				editing: {
					openNew: false,
					openDelete: false,
					paraEliminar: {},
					pagina: 1,
					titulo: '',
					moduloError: false,
					formulario: { obligatorio: false, principal: {} }
				},
				data: {}
			}
		default:
			return state
	}
}

import { SET_UNIDAD, LOGOUT } from '../constants'

export const unidad = (
	state = {
		unidad: {
			id: window.location.pathname
				.substring(window.location.pathname.indexOf('/dashboard'), window.location.pathname.length)
				.split('/')[6]
		}
	},
	a
) => {
	switch (a.type) {
		case SET_UNIDAD:
			return { ...state, unidad: a.payload }
		case LOGOUT:
			return {}
		default:
			return state
	}
}

import React from 'react'
import { TextField } from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'

export function VideoPrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props
	return (
		<TextField
			className={classes.input}
			name='link'
			value={principal.link}
			variant='filled'
			onChange={({ target: { value, name } }) =>
				dispatch(
					onChangeModulo({
						name: 'formulario',
						value: { ...formulario, principal: { ...principal, [name]: value } }
					})
				)}
			label='Hipervínculo'
			required
			error={moduloError && !principal.link}
		/>
	)
}

export function VideoRender(props) {
	const { modulo } = props
	return (
		<iframe
			title='video'
			width='520'
			height='315'
			allowFullScreen
			src={modulo.principal.link.replace('watch?v=', 'embed/').replace('.be', 'be.com/embed')}
		/>
	)
}

export const videoValidar = principal => (principal.link ? true : false)

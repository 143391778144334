import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	makeStyles,
	List,
	ListItem,
	ListItemText,
	Grid,
	Button,
	Paper,
	ListItemIcon,
	Checkbox,
	Slide,
	ListSubheader,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	IconButton,
	Typography,
	Divider,
	CardHeader,
	Card,
	CardActions,
    Box,
    useTheme
} from '@material-ui/core'

import { apiPost, apiGet, apiGetWithParams, apiPut, apiDelete } from '../../../api'
import { urlGrupos, urlUsuarios, urlGRupoUsuario } from '../../../api/urls'
import { setUsuarios } from '../../../actions/usuarios/setUsuarios'
import { setGrupos } from '../../../actions/grupos/setGrupos'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { green } from '@material-ui/core/colors'
import { Add, Edit, Delete } from '@material-ui/icons'
import { hasPermission, permisos } from '../../../constants/permisos'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		height: '100%'
	},
	listaGrupos: {
		borderRadius: 4,
		flex: 1,
		overflow: 'auto',
		paddingTop: 0
	},
	gruposWrapper: {
		width: '25%',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[2],
		marginRight: 5,
		borderRadius: 4,
		height: '100%',
		paddingTop: 0,
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 5,
		marginBottom: 'auto'
	},
	contenedorDerecho: {
		display: 'flex',
		height: '100%',
		width: '75%',
		flexDirection: 'column'
	},
	listaUsuarios: {
		display: 'flex',
		flexGrow: 1,
		marginLeft: 5,
		height: 'calc(100% - 40px)'
	},
	listWrapper: {
		margin: 'auto',
		height: '100%'
	},
	listContainer: {
		width: '44%',
		paddingTop: 0,
		height: '100%'
	},
	buscarContainer: {
		height: 70
	},
	list: {
		height: 'calc(100% - 70px)',
		overflowY: 'auto'
	},
	paper: {
		width: '100%',
		height: '100%'
		//overflow: 'auto'
	},
	button: {
		margin: theme.spacing(0.5, 0)
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		paddingRight: 8
	},
	addButtonWrapper: {
		alignSelf: 'flex-end'
	},
	addButton: {
		boxShadow: theme.shadows[4],
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark
		}
	},
	addButtonLabel: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginLeft: 8 + 5,
		height: 40
	}
}))

function not(a, b) {
	return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
	return a.filter(value => b.indexOf(value) !== -1)
}

export default function Grupos() {
	const [ state, setState ] = useState({
		grupoSeleccionado: -1,
		left: [],
		right: [],
		checked: [],
		nombre: '',
		openNew: false,
		openDelete: false,
		grupoId: -1,
		lSearch: '',
		rSearch: ''
	})
	const dispatch = useDispatch()
	const classes = useStyles()
	const grupos = useSelector(state => state.grupos.lista)
	const usuarios = useSelector(state => state.usuarios.lista)
	const rol = useSelector(state => state.login.rol)
    const infoPermisos = useSelector(state => state.permisos)
    const theme= useTheme()

	const getUsuarios = () => {
		dispatch(startLoading())
		apiGet(urlUsuarios).then(r => {
			if (r.status === 'OK') {
				dispatch(setUsuarios(r.data))
			} else dispatch(openSnack({ texto: 'Error al obtener los usuarios', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const getGrupos = () => {
		dispatch(startLoading())
		apiGet(urlGrupos).then(r => {
			if (r.status === 'OK') {
				dispatch(setGrupos(r.data))
			} else dispatch(openSnack({ texto: 'Error al obtener grupos', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	useEffect(() => {
		getGrupos()
		getUsuarios()
		// eslint-disable-next-line
	}, [])

	const inicializar = left => {
		const res = usuarios.filter(e => left.filter(b => b.id === e.id).length === 0)
		setState(prevState => ({ ...prevState, right: res, left }))
	}

	const getUsuariosGrupo = grupo => {
		dispatch(startLoading())
		apiGetWithParams(urlUsuarios, { grupoId: grupo.id }).then(r => {
			if (r.status === 'OK') inicializar(r.data.map(e => ({ ...e, old: true })))
			else dispatch(openSnack({ texto: 'Error al obtener usuarios del grupo', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const setLeft = left => {
		setState(ps => ({ ...ps, left }))
	}

	const setRight = right => {
		setState(ps => ({ ...ps, right }))
	}

	const setChecked = checked => {
		setState(ps => ({ ...ps, checked }))
	}

	const leftChecked = () => {
		const { checked, left } = state
		return intersection(checked, left)
	}
	const rightChecked = () => {
		const { checked, right } = state
		return intersection(checked, right)
	}

	const handleToggle = value => () => {
		const { checked } = state
		const currentIndex = checked.indexOf(value)
		const newChecked = [ ...checked ]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	const handleAllRight = () => {
		const { left, right } = state
		setRight(right.concat(left))
		setLeft([])
		setChecked([])
	}

	const handleCheckedRight = () => {
		const { left, right, checked } = state
		setRight(right.concat(leftChecked()))
		setLeft(not(left, leftChecked()))
		setChecked(not(checked, leftChecked()))
	}

	const handleCheckedLeft = () => {
		const { left, right, checked } = state
		setLeft(left.concat(rightChecked()))
		setRight(not(right, rightChecked()))
		setChecked(not(checked, rightChecked()))
	}

	const handleAllLeft = () => {
		const { left, right } = state
		setLeft(left.concat(right))
		setChecked([])
		setRight([])
	}

	const listaTabla = (items, side, search) => {
		const { checked, rSearch, lSearch } = state
		return (
			<Paper className={classes.paper}>
				<Box className={classes.buscarContainer}>
					<TextField
						style={{ padding: 5, width: '100%' }}
						name={side + 'Search'}
						placeholder='Buscar'
						variant='outlined'
						onChange={({ target: { name, value } }) => setState(ps => ({ ...ps, [name]: value }))}
						value={side === 'r' ? rSearch : lSearch}
					/>
				</Box>
				<Divider />
				<List className={classes.list} component='div' disablePadding role='list' subheader={<li/>}>
					<ListSubheader style={{backgroundColor: theme.palette.background.paper}} color='primary'>
						{side === 'l' ? 'Usuarios en grupo seleccionado' : 'Agregar usuarios'}
					</ListSubheader>
					{items.length ? (
						items.filter(e => e.nombreCompleto.toLowerCase().indexOf(search) !== -1).map(value => (
							<ListItem
								key={value.id}
								role='listitem'
								style={{
									backgroundColor:
										(value.old && side === 'r') || (!value.old && side === 'l')
											? green[200]
											: 'inherit'
								}}
							>
								{hasPermission(rol, infoPermisos, permisos.ASIGNAR_USUARIO_GRUPO) ? (
									<ListItemIcon key={'liri-' + value.id}>
										<Checkbox
											color='primary'
											key={'cksds-' + value.id}
											checked={checked.indexOf(value) !== -1}
											tabIndex={-1}
											disableRipple
											onClick={handleToggle(value)}
										/>
									</ListItemIcon>
								) : null}
								<ListItemText
									//style={{ color: (value.old && side === 'r') || (!value.old && side === 'l') ? green[500] : 'inherit' }}
									key={'lutsdad-' + value.id}
									primary={`${value.nombreCompleto}`}
								/>
							</ListItem>
						))
					) : (
						<ListItem style={{ paddingLeft: 32 }} role='listitem'>
							<ListItemText primary='No hay usuarios para mostrar' />
						</ListItem>
					)}
				</List>
			</Paper>
		)
	}

	const tabla = () => {
		const { left, right, lSearch, rSearch } = state
		const puedeAsignar = hasPermission(rol, infoPermisos, permisos.ASIGNAR_USUARIO_GRUPO)
		return (
			<Grid container spacing={2} justify='center' alignItems='stretch' className={classes.listWrapper}>
				<Grid style={{ width: '44%', paddingTop: 0, height: '100%' }} item>
					{listaTabla(left, 'l', lSearch.toLowerCase())}
				</Grid>
				{puedeAsignar ? (
					<Grid item style={{ width: '12%' }}>
						<Grid
							style={{ height: '100%' }}
							container
							direction='column'
							justify='center'
							alignItems='center'
						>
							<Button
								variant='outlined'
								size='small'
								className={classes.button}
								onClick={handleAllRight}
								disabled={left.length === 0}
								aria-label='move all right'
							>
								≫
							</Button>
							<Button
								variant='outlined'
								size='small'
								className={classes.button}
								onClick={handleCheckedRight}
								disabled={leftChecked().length === 0}
								aria-label='move selected right'
							>
								&gt;
							</Button>
							<Button
								variant='outlined'
								size='small'
								className={classes.button}
								onClick={handleCheckedLeft}
								disabled={rightChecked().length === 0}
								aria-label='move selected left'
							>
								&lt;
							</Button>
							<Button
								variant='outlined'
								size='small'
								className={classes.button}
								onClick={handleAllLeft}
								disabled={right.length === 0}
								aria-label='move all left'
							>
								≪
							</Button>
						</Grid>
					</Grid>
				) : null}
				{puedeAsignar ? (
					<Grid className={classes.listContainer} item>
						{listaTabla(right, 'r', rSearch.toLowerCase())}
					</Grid>
				) : null}
			</Grid>
		)
	}

	const actualizarGrupo = () => {
		getUsuariosGrupo({ id: state.grupoSeleccionado })
	}

	const onGuardarCambios = () => {
		dispatch(startLoading())
		const nuevos = []
		state.left.forEach(e => {
			if (!e.old) nuevos.push(e.id)
		})
		const eliminados = []
		state.right.forEach(e => {
			if (e.old) eliminados.push(e.id)
		})
		apiPost(urlGRupoUsuario, { grupoId: state.grupoSeleccionado, nuevos, eliminados }).then(r => {
			if (r.status === 'OK') {
				actualizarGrupo()
				dispatch(openSnack({ texto: 'Cambios guardados con éxito', tipo: 'success' }))
			} else dispatch(openSnack({ texto: 'Error al guardar cambios', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const onDeshacer = () => {
		const left = []
		const right = []
		state.left.forEach(e => {
			if (e.old) left.push(e)
			else right.push(e)
		})
		state.right.forEach(e => {
			if (e.old) left.push(e)
			else right.push(e)
		})
		setState(ps => ({ ...ps, left, right }))
	}

	const crearGrupo = () => {
		const { grupoId, nombre } = state
		if (nombre) {
			dispatch(startLoading())
			if (state.edit)
				apiPut(urlGrupos, { grupoId, nombre }).then(r => {
					if (r.status === 'OK') {
						getGrupos()
						setState(ps => ({ ...ps, openNew: false, nombre: '', edit: false, grupoId: -1 }))
						dispatch(openSnack({ texto: 'Grupo editado con éxito', tipo: 'success' }))
					} else dispatch(openSnack({ texto: 'Error al editar el grupo', tipo: 'error' }))
					dispatch(endLoading())
				})
			else
				apiPost(urlGrupos, { nombre: state.nombre }).then(r => {
					if (r.status === 'OK') {
						getGrupos()
						setState(ps => ({ ...ps, openNew: false, nombre: '' }))
						dispatch(openSnack({ texto: 'Grupo creado con éxito', tipo: 'success' }))
					} else dispatch(openSnack({ texto: 'Error al crear el grupo', tipo: 'error' }))
					dispatch(endLoading())
				})
		}
	}

	const eliminarGrupo = () => {
		dispatch(startLoading())
		apiDelete(urlGrupos, { grupoId: state.grupoId }).then(r => {
			if (r.status === 'OK') {
				getGrupos()
				setState(ps => ({ ...ps, openDelete: false, nombre: '', edit: false, grupoId: -1 }))
				dispatch(openSnack({ texto: 'Grupo eliminado con éxito', tipo: 'success' }))
			} else dispatch(openSnack({ texto: 'Error al eliminar grupo', tipo: 'error' }))
			dispatch(endLoading())
		})
	}

	const { openNew, grupoSeleccionado, nombre, openDelete, edit } = state

	console.log(state)

	return (
		<div className={classes.root}>
			<Card className={classes.gruposWrapper}>
				<CardHeader titleTypographyProps={{ color: 'primary', variant: 'h5' }} title='Lista de grupos' />
				<Divider />
				<List className={classes.listaGrupos}>
					{grupos.map((g, i) => (
						<ListItem
							button
							key={`item-${i}-${g.id}`}
							selected={grupoSeleccionado === g.id}
							onClick={() => {
								setState(prevState => ({ ...prevState, grupoSeleccionado: g.id }))
								getUsuariosGrupo(g)
							}}
						>
							<ListItemText primary={g.nombre} />
							{hasPermission(rol, infoPermisos, permisos.EDITAR_GRUPO) ? (
								<IconButton
									onClick={() =>
										setState(ps => ({
											...ps,
											nombre: g.nombre,
											grupoId: g.id,
											openNew: true,
											edit: true
										}))}
								>
									<Edit color='primary' />
								</IconButton>
							) : null}
							{hasPermission(rol, infoPermisos, permisos.ELIMINAR_GRUPO) ? (
								<IconButton
									onClick={() =>
										setState(ps => ({ ...ps, nombre: g.nombre, grupoId: g.id, openDelete: true }))}
								>
									<Delete color='error' />
								</IconButton>
							) : null}
						</ListItem>
					))}
				</List>
				{hasPermission(rol, infoPermisos, permisos.CREAR_GRUPO) ? (
					<CardActions className={classes.addButtonWrapper}>
						<IconButton
							className={classes.addButton}
							onClick={() => setState(ps => ({ ...ps, openNew: true }))}
						>
							<Add />
						</IconButton>
					</CardActions>
				) : null}
			</Card>
			<Slide direction='left' in={grupoSeleccionado !== -1} mountOnEnter unmountOnExit>
				<div className={classes.contenedorDerecho}>
					<div className={classes.listaUsuarios}>{tabla()}</div>
					{hasPermission(rol, infoPermisos, permisos.ASIGNAR_USUARIO_GRUPO) ? (
						<div className={classes.buttonsContainer}>
							<Button
								style={{
									backgroundColor: green[500],
									color: 'white'
								}}
								variant='contained'
								onClick={() => dispatch(openSnack({ texto: 'Función en desarrollo', tipo: 'info' }))}
							>
								Cargar excel
							</Button>
							<div className={classes.buttons}>
								<Button color='secondary' variant='text' onClick={onDeshacer}>
									Deshacer cambios
								</Button>
								<Button color='primary' variant='contained' onClick={onGuardarCambios}>
									Guardar
								</Button>
							</div>
						</div>
					) : null}
				</div>
			</Slide>
			<Dialog
				open={openNew || openDelete}
				onClose={() =>
					setState(ps => ({
						...ps,
						openDelete: false,
						openNew: false,
						nombre: '',
						edit: false,
						grupoId: -1
					}))}
			>
				<DialogTitle>{openNew ? 'Crear' : edit ? 'Editar' : 'Eliminar'} grupo</DialogTitle>
				<DialogContent>
					{openDelete ? (
						<Typography>{nombre}</Typography>
					) : (
						<TextField
							className={classes.textField}
							value={nombre}
							onChange={({ target: { value } }) => setState(ps => ({ ...ps, nombre: value }))}
							label='Nombre'
							fullWidth
							variant='filled'
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button color='primary' variant='contained' onClick={openDelete ? eliminarGrupo : crearGrupo}>
						{openDelete ? 'Eliminar' : 'Guardar'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

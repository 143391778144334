import React, { useEffect, useState } from 'react'
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Typography
} from '@material-ui/core'
import { apiGetWithParams } from '../../../api'
import { openSnack } from '../../../actions/feedback/openSnack'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import { urlUsuarios } from '../../../api/urls'
import { useDispatch, useSelector } from 'react-redux'
import { PersonRounded } from '@material-ui/icons'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	imagen: {
		width: 150,
		height: 150,
		/* los siguientes valores son independientes del tamaño del círculo */
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50%',
		borderRadius: '50%',
		backgroundSize: '100% auto',
		border: '1px solid ' + grey['300']
	}
}))

export default function Perfil(props) {
	const { open, onClose, onEdit } = props
	const classes = useStyles()
	const [ userInfo, setUserInfo ] = useState({})
	const id = useSelector(state => state.login.id)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(startLoading())
		apiGetWithParams(urlUsuarios, { id })
			.then(r => {
				if (r.status === 'OK') setUserInfo(r.data)
				else dispatch(openSnack({ texto: 'Error al obtener los datos del perfil', tipo: 'error' }))
				dispatch(endLoading())
			})
			.then(e => console.log(e))
	}, [])

	return (
		<Dialog open={open}>
			<DialogTitle disableTypography className={classes.title}>
				{userInfo.icono ? (
					<img
						className={classes.imagen}
						src={userInfo.icono}
						alt={'imagen de perfil'}
						height='40%'
						width='40%'
					/>
				) : (
					<Avatar>
						<PersonRounded />
					</Avatar>
				)}
				<Typography variant='h5'>{userInfo.nombreCompleto}</Typography>
			</DialogTitle>
			<DialogContent className={classes.content} dividers>
				<Typography>Nombre de usuario: {userInfo.username}</Typography>
				<Typography>Mail: {userInfo.email}</Typography>
				<Typography>DNI: {userInfo.nombreCompleto}</Typography>
				<Typography>Fecha de nacimiento: {userInfo.fecha_nacimiento}</Typography>
				<Typography>Rol: {userInfo.rol}</Typography>
				<Typography>Cursos: -Proximamente-</Typography>
				<Typography>Logros: -Proximamente-</Typography>
			</DialogContent>
			<DialogActions>
				<Button color='secondary' variant='text' onClick={onClose}>
					Cerrar
				</Button>
				<Button color='primary' variant='contained' onClick={onEdit}>
					Modificar perfil
				</Button>
			</DialogActions>
		</Dialog>
	)
}

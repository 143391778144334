import { SET_PERMISOS, LOGIN } from '../constants'

export const permisos = (state = {}, a) => {
	switch (a.type) {
		case SET_PERMISOS:
			return a.payload
		case LOGIN:
			return a.payload.permisos || {}
		default:
			return state
	}
}

import React from 'react'
import styles from './styles.module.css'
import httpServer, { httpDigitalOcanStorage } from '../httpMedia/'

const Animacion = () => {
    return (
        <div className={styles.animacion_container}>
            <video className={styles.img_principal} alt="" autoPlay loop muted>
                <source src={`${httpDigitalOcanStorage}frontpage-demo/frontDemo.mp4`} type="video/mp4"/>
            </video>
                <div className={styles.contenido}>
                    {/* <div className={styles.contenido_imagenes}>
                        <a className={styles.img_wedrim} href="http://www.Wedrim.Com" target="_blank" rel="noopener noreferrer">
                        <img className={styles.wedrim_img} src={`${httpServer}NfoW7v.svg`} alt="" />
                        </a>
                        <a className={styles.img_cse} href="http://www.cseducacion.com.ar" target="_blank" rel="noopener noreferrer">   
                        <img src={`${httpServer}Trazado%209.svg`} alt="" />
                        </a>
                        <a className={styles.img_ogi} href="http://www.ogitech.es" target="_blank" rel="noopener noreferrer">
                        <img src={`${httpDigitalOcanStorage}logo_ogi_blanco%20%281%29.png`} alt="" />
                        </a>
                        <a className={styles.img_acorla} href="http://www.Acorla.com" target="_blank" rel="noopener noreferrer">
                        <img src={`${httpServer}ACORLA_blanco.png`} alt="" />
                        </a>
                    </div> */}
                    <div className={styles.contenido_texto}>
                        <p>Las mejores instituciones dedicadas a la educación y tecnología para ofrecerte contenido de calidad en una plataforma optimizada para tu aprendizaje</p>
                    </div>
                </div>
        </div>
    )
}

export default Animacion

import url from './server'

export const urlLogin = `${url}login`
export const urlCurso = `${url}cursos`
export const urlUnidades = `${url}unidades`
export const urlTematicas = `${url}tematicas`
export const urlModulos = `${url}modulos`
export const urlRespuestas = `${url}respuestas`
export const urlUsuarios = `${url}users`
export const urlGrupos = `${url}grupos`
export const urlGRupoUsuario = `${url}grupoUsuario`
export const urlDivisiones = `${url}divisiones`
export const urlPermisos = `${url}permisos`
export const urlRoles = `${url}roles`
export const urlMetadata = `${url}metadata`
export const urlFaq = url+'faq'
export const urlChangePass = url+'changePassword'
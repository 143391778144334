import React, { useState } from 'react'
import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core'
import { onChangeModulo } from '../../../../../actions/modulos/onChangeModulo'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeRespuestaModulo } from '../../../../../actions/modulos/onChangeRespuestaModulo'

const useStyles = makeStyles(theme => ({
	root: {}
}))

export function PreguntaLibrePrincipal(props) {
	const { moduloError, formulario } = useSelector(state => state.modulos.editing)
	const { principal } = formulario
	const dispatch = useDispatch()
	const { classes } = props

	return (
		<TextField
			className={classes.input}
			name='pregunta'
			value={principal.pregunta}
			variant='filled'
			onChange={({ target: { name, value } }) =>
				dispatch(
					onChangeModulo({
						name: 'formulario',
						value: { ...formulario, principal: { ...principal, [name]: value } }
					})
				)}
			label='Pregunta'
			multiline
			rowsMax={5}
			rows={2}
			required
			error={moduloError && !principal.pregunta}
		/>
	)
}

export function PreguntaLibreRender(props) {
    const { modulo, classes, guardarRespuesta, responderPermiso } = props
	const data = useSelector(state => state.modulos.data[modulo.id] || {})

	const dispatch = useDispatch()

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography>{modulo.principal.pregunta}</Typography>
			<TextField
				className={classes.input}
				name='respuesta'
				value={modulo.respuesta ? modulo.respuesta.respuesta : data.respuesta}
				variant='filled'
				onChange={({ target: { name, value } }) => dispatch(onChangeRespuestaModulo({ [name]: value }))}
				label='Respuesta'
				multiline
				rowsMax={5}
				rows={2}
				fullWidth
				disabled={modulo.respuesta ? true : responderPermiso ? false : true}
			/>
			<Button
				disabled={modulo.respuesta ? true : responderPermiso ? false : true}
				style={{ marginBottom: 10, alignSelf: 'flex-end' }}
				color='primary'
				variant='contained'
				onClick={() => guardarRespuesta({ moduloId: modulo.id, data: { respuesta: data.respuesta } })}
			>
				Responder
			</Button>
		</div>
	)
}

export const preguntaLibreValidar = principal => principal.pregunta